
const medialist = {
	yes0:  { object: require('../img/icons/happy0.png'), wd: 128, ht: 128 },
	yes1:  { object: require('../img/icons/happy1.png'), wd: 128, ht: 128 },
	soso0: { object: require('../img/icons/soso0.png'), wd: 128, ht: 128 },
	soso1: { object: require('../img/icons/soso1.png'), wd: 128, ht: 128 },
	no0:   { object: require('../img/icons/sad0.png'), wd: 128, ht: 128 },
	no1:   { object: require('../img/icons/sad1.png'), wd: 128, ht: 128 },
}

function getResource(key) {
	if (typeof key !== 'string') {
		console.log(key);
		throw new Error('Filename is not a string');
	}
	if (typeof medialist[key] === 'undefined') 
		throw new Error('Unable to locate entry for image "' + key + '"');
	return medialist[key];
}

export default {
  getResource,
}	