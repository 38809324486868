import React, { Component } from 'react';
import {
  Text,
  View,
  Image,
  ImageBackground,
  TouchableOpacity,
  Picker,
  ScrollView,
  Dimensions,
  Platform,
} from 'react-native';

import Styles, {HeaderBar, documentTitle} from '../theme/Styles';
import { withNavigation,  DrawerActions } from 'react-navigation';
import Storage from '../components/Storage';
import GlobalSettings from '../components/GlobalSettings';
import Frame from '../components/Frame';
// import RNPickerSelect from 'react-native-picker-select';
import Resource from '../components/Resource';

import FontAwesomeIcon from 'react-native-vector-icons/FontAwesome5';
import { Hideo } from 'react-native-textinput-effects';

import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import icoMoonConfig from '../theme/icomoon.json';
const expoAssetId = require('../theme/icomoon.ttf');
const Icon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', expoAssetId);

// https://github.com/halilb/react-native-textinput-effects
// https://www.kissclipart.com/red-circle-clipart-red-circle-drawing-e2wp6m/

// page 0: generic splash

// page 1: Select Language, agree to terms
//				-- new version of app whenever a new language is released
//				18 or over?  

// page 2: enter Procedure Code (leave blank if unknown)
//						show sample QRCode and the text that is to be entered
//			this page is skipped if from website, or already entered.

// this page is now obsolete - go to a specific demo ->
// page 3: select procedure (if blank, or qr from sample image is entered)
//					display text indicating the guide will contain generic info. Individual hospitals and physicians will have different whatevers
//				-- WarnOnBlur will be disabled
//				-- People page will be generic, 

//			select country (911 phone number, weight/temp units)

class ConfigPage extends Component {

	constructor (props) {
		super(props);
		this.state = {
			pg: 2,
			message: false,
			procedure_selected: null,
			lastpg: false,
			lastproc: null,
			lastmsg: false,
		};
		this.procedure_code = '';
	}
	
	static getDerivedStateFromProps(props, state) {
		let result = [];
		let changed = false;
		if (state.pg != state.lastpg) {
			if (state.pg == 2) ConfigPage.getDetectedProcedure(state);	// do we have a Detected Procedure - set as default and continue
			result.pg = state.pg;
			result.lastpg = state.pg;
			if (result.pg == 3) {		// get the first procedure
				state.procedure_selected = null;
				global.master.procedures.forEach((value, i) => {
					if (value.generic) {
						if (state.procedure_selected === null) state.procedure_selected = value.abbr;
					}
				});
			}
			changed = true;
		}
		if (state.procedure_selected !== state.lastproc) {
			result.procedure_selected = state.procedure_selected;
			result.lastproc = state.procedure_selected;
			changed = true;
		}
		if (state.message != state.lastmsg) {
			result.message = state.message;
			result.lastmsg = state.message;
			changed = true;
		}
		return changed ? result : null;
	}

	static getDetectedProcedure(state) {
		let procedurecode = Resource.detectedProduct();
		if (procedurecode !== null) {
			state.procedure_selected = procedurecode;
			state.pg = 99;
		}
	}
	
	change = (event) => {
		this.procedure_code = event.nativeEvent.text.trim();
	}
	
	changeSelect = (itemValue) => {
		this.setState({procedure_selected: itemValue});
	}

	onPress2 = () => {
        let defaultChosen = this.procedure_code === '' || this.procedure_code === 'care' || this.procedure_code.substring(0,2) == 'xx';
		if (defaultChosen) {
            this.procedure_code = GlobalSettings.getDefaultProcedure();
			this.setState({pg: 3});
        }

        // if procedure specified, make sure it is valid
        let value = this.procedure_code.toLowerCase();
        let found = GlobalSettings.getProductInfo(value, false);

        // production version will look for published guides before getting beta versions
        if (found === null) {
            GlobalSettings.procedure_checkNew(0, value).then(info => {
                if (info !== null) {
                    this.saveSelectedProcedure(info, 1);
                    return info;
                } else {
                    return GlobalSettings.procedure_checkNew(1, value);
                }
            }).then(info => {
                if (info !== null) {
                    this.saveSelectedProcedure(info, 2);
                } else if (defaultChosen) {
                    this.setState({pg: 2, message: 'Please enter a Procedure Code' });
                } else {
                    this.setState({pg: 2, message: '"' + this.procedure_code + '" is not a recognized Procedure Code' });
                }
            });
        } else {
            this.saveSelectedProcedure(found, 3);
		}
	}

	saveSelectedProcedure(procedure_info, x) {
		let value = procedure_info.abbr.toLowerCase().trim();
        let group = procedure_info.group;
        if (group) {        // open webbrowser to select a procedure
            this.setState({pg: 2});
            Resource.openUrl(procedure_info.location);
            return;
        }
		let location = procedure_info.location ? procedure_info.location : 0;
		if (typeof location == 'number') location = location.toString();
        // are we going to be redirected to another site?
        if (Resource.javascriptEvent('selection', {
				product: value,
				location: location
			})) {
                // no, continue as normal
                Storage.local_setValue('procedure', value);
                Storage.local_setValue('location', location);
                Storage.local_setValue('introduction', '1');
                this.props.onFinish();					// all done with these pages.
        } else {
            // do not go past Go.
            this.setState({pg: 2});
            Storage.trigger('restart', {});
        }
	}

	onPress3 = () => {
		let found = GlobalSettings.getProductInfo(this.state.procedure_selected);
		this.saveSelectedProcedure(found, 4);
	}

	submitting = (e) => {
		this.onPress2();
	}

    // icon = 60px
    // submit icon
    // padding: 16
	showinput(wd) {
        let WIDTH_ICON = 60;
        let WIDTH_SUBMIT = 40;
        if (wd > 350) wd = 350;
        let styles = {
            flex: 1, 
            flexDirection: 'row', 
            justifyContent: 'flex-start', 
            alignItems: 'center',
            width: wd,
        };
        let styles_h = {
            marginBottom: 10, 
            marginRight: 10,
        };
		return (
			<View style={styles}>
				<Hideo
					style={styles_h}
					iconClass={FontAwesomeIcon}
					iconName={'link'}
					iconColor={'white'}
					iconBackgroundColor={'#b6b8ab'}
					inputStyle={{ color: '#464949', paddingRight: 0}}
					onChange={this.change}
					onSubmitEditing={this.submitting}
					autoCorrect={false}
					autoCapitalize={'none'}
				  />
				<TouchableOpacity onPress={this.onPress2} style={{height: '100%'}}>
					<Icon name="circle-right" size={44} color="#fff" />
				</TouchableOpacity>
			</View>
		);
	}

	page2(width, wd) {
//		let ht = wd / 500 * 239;	// contentContainerStyle={{width: width}}
		let ht = (wd / 500 * 335) - 20;	// contentContainerStyle={{width: width}}  , marginBottom: 10
		documentTitle('Enter your Procedure Code');
		return (
			<ScrollView style={{width: width}}>
				<View style={{width: wd - 10, flex: 1, flexDirection: 'column', justifyContent: 'flex-start', alignSelf: 'center'}}>
					<View>
						<Image source={require('../theme/productcode.png')} style={{width: wd, height: ht, marginTop: 20, marginBottom: -30}} />
						<Text style={{marginBottom: 10, fontSize: 26, color: '#fff'}}>Procedure Code</Text>
					</View>
					{this.showinput(wd)}
					<View>
						{this.state.message && <Text style={{marginBottom: 10, fontSize: 16, color: '#f00', fontWeight: 'bold'}}>{this.state.message}</Text>}
					</View>
					<View>
						<Text style={{marginBottom: 10, fontSize: 16, color: '#fff'}}>You’ll find the Procedure Code below the QR Code on the card
you were given.{`\n\n`}
If you do not have a Procedure Code, please leave it blank and you'll have access to a sample procedure.</Text>
					</View>
				</View>
			</ScrollView>
		);
	}

	// if all steps are completed, this will return null.
	render() {
		var {height, width} = Dimensions.get('window');
		let land = width > height;
		let body;
		let wd = width - 80;
		if (wd > 500) wd = 500;	// 500 x 239
		
		switch(this.state.pg) {
			case 2:
				body = this.page2(width - 20, wd);
				break;
			case 99:
				let found = GlobalSettings.getProductInfo(this.state.procedure_selected);
				this.saveSelectedProcedure(found, 5);
				return null;
			default:
				body = (
					<Text>Unknown Config Page</Text>
				);
				break;
		}
		return (
			<View style={{backgroundColor: '#0099cc', height: '100%'}}>
				{body}
			</View>
		);
	}
	
}

export default ConfigPage;
