import React, { Component } from 'react';
import { Text, View, TouchableOpacity, Dimensions, Platform, StyleSheet } from 'react-native';
import {Calendar, CalendarList, Agenda} from 'react-native-calendars';
import Styles from '../theme/Styles';
import Storage from '../components/Storage';
import ProcedureData from '../components/ProcedureData';

import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import icoMoonConfig from '../theme/icomoon.json';
const expoAssetId = require("../theme/icomoon.ttf");
const IconMoon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', expoAssetId);
const XDate = require('xdate');

// https://github.com/wix/react-native-calendars
// https://arshaw.com/xdate/#Formatting

let crossReference;
let categories;
let week_nav;
let theme = StyleSheet.flatten(Styles.theme);

function load() {
	// assemble a list of pagenumbers, with their durations
	// apply current date to all at-home pages
	crossReference = [];
	categories = {};
	let content_text = ProcedureData.contentText();
	let content = content_text.content;
	
	week_nav = content_text.splash.week_nav || [];

	// look for our Calendar pages
	for(let i = 0; i < content.length; i++) {
		_load_processPage(content[i], i);
	}
}

function _load_processPage(page, indexnum) {
	if (page.type == 'calendar' && typeof page.duration === 'object' && page.duration !== null) {
		if (typeof page.duration.category !== 'undefined') {
			let category = page.duration.category;
			if (typeof categories[category] === 'undefined') categories[category] = 0;
			if (page.duration.caption > categories[category]) categories[category] = page.duration.caption;
		}
		crossReference.push({index: indexnum, duration: page.duration})
		return true;
	}
	if (typeof page.children !== 'undefined') {
		for(let j = 0; j < page.children.length; j++) {
			_load_processPage(page.children[j], indexnum);
		}
	}
	return false;
}

function _drawArrow(direction) {
	var iconname = direction === 'left' ? 'arrow-left2' : 'arrow-right2';
	return (
		<IconMoon name={iconname} color={theme.color} size={24} />
	);
}

// update database
function _onPressDay(date, props) {
	Storage.local_setValue('return-home', date.dateString);
	props.onDatePress(date.dateString);
}

function _onPressChange(props) {
	props.onChangePress();
}

function _onPressHeader(props, type, value, duration) {
	props.onHeaderPress(type, value, duration);
}

function _getHeaderCaption(colnum) {
	if (Object.keys(categories).length == 1 && typeof categories.Week != 'undefined') {		// Weeks
		return {category: 'Week', caption: colnum + 1};
	}
	let p = -1;
	// skip day entries
	for(let i = 0; i < crossReference.length; i++) {
		let node = crossReference[i].duration;
		if (typeof node.category !== 'undefined') {
			p++;
			if (colnum == p) return node;
		}
	}
	return {category: 'Week', caption: colnum};
}

function _isActive(duration, week_nav_item) {
	let category = week_nav_item.category;
	if (typeof duration.day !== 'undefined') {
		return category == 'Week' && duration.week == week_nav_item.week_num;
	}
	return category == duration.category && week_nav_item.week_num == duration.week_num;
}

function _buildWeekNav(props, page, pt_w) {
	let topline = [], active;
	
	for(let i = 0; i < week_nav.length; i++) {
		let category = week_nav[i].category;
		let caption = week_nav[i].caption;
		let active = _isActive(page.duration, week_nav[i]);
		let cellstyle = active ? {backgroundColor: '#a1a1a1'} : {backgroundColor: '#fff'};
		let itemstyle = active ? {color: '#fff'} : {color: '#a1a1a1'};

		topline.push(
			<TouchableOpacity key={i} onPress={ () => {_onPressHeader(props, category, caption, page.duration)} } 
				style={[cellstyle, Styles.calendarNav]}
			>
				<View style={{margin: 0, padding: 0}}>
					<Text style={[itemstyle, {fontSize: pt_w, fontWeight: 'bold', alignSelf: 'center'}]}>{category} {caption}</Text>
				</View>
			</TouchableOpacity>
		);
		
	}
	return topline;
}

// if dt is blank, show a default header Week 1, Day 1, Day 2, Stage 3, Stage 4 etc
function _onShowHeader(props, page, key, dt) {
	let {width} = Dimensions.get('window');
	let pt_w, pt_dow, pt_num, date, dow, daynum, active;
	let cap, subcap, action, cellstyle, itemstyle;
	if (width < 440) {
		pt_dow = 10;
	} else {
		pt_dow = 16;
	}
	pt_w = pt_dow * 1.15;
	pt_num = pt_dow * 2.20;
	
	let weekcount = page.duration.max;
	let topline = [];
	if (week_nav.length) {
		topline = _buildWeekNav(props, page, pt_w);
		weekcount = week_nav.length;
	} else {
		if (typeof weekcount === 'string') {
			weekcount = parseInt(weekcount);
		}
		for(let i = 0; i < weekcount; i++) {
			let {category, caption} = _getHeaderCaption(i);
			
	/*			cap = 'Week';
				subcap = i + 1; */
			
			if (typeof page.duration['category'] !== 'undefined') {
				active = page.duration['category'] == category && page.duration['caption'] == caption;
			} else {
				active = i + 1 == page.duration.week;
			}
			cellstyle = active ? {backgroundColor: '#a1a1a1'} : {backgroundColor: '#fff'};
			itemstyle = active ? {color: '#fff'} : {color: '#a1a1a1'};

			topline.push(
				<TouchableOpacity key={i} onPress={ () => {_onPressHeader(props, category, caption, page.duration)} } 
					style={[cellstyle, Styles.calendarNav]}>
					<View>
						<Text style={[itemstyle, {fontSize: pt_w, fontWeight: 'bold', alignSelf: 'center'}]}>{category} {caption}</Text>
					</View>
				</TouchableOpacity>
			);
		}
	}
	if (weekcount) {
		topline = (
			<View style={{flex: weekcount, flexDirection: 'row', alignItems: 'flex-start'}}>
				{topline}
			</View>
		);
	}
	
	let dayline = null;
	if (typeof page.duration.day !== 'undefined') {
		dayline = [];
		var isGeneric = dt == '';
		if (isGeneric) {
			date = '';
		} else {
			date = XDate(dt);
			date.addDays(7 * (page.duration.week - 1));
		}
		let max_day = typeof page.duration.max_day !== 'undefined' ? page.duration.max_day : 7;
		for(let i = 0; i < max_day; i++) {
			if (isGeneric) {
				dow = 'Day';
				daynum = i + 1;
			} else {
				dow = date.toString('ddd').toUpperCase();
				daynum = date.toString('d');
			}
			let dayoffset = 7 * (page.duration.week - 1) + i + 1;
			active = dayoffset == page.duration.start;
			let cellstyle = active ? {backgroundColor: '#29a5cf'} : {backgroundColor: '#fff'};
			let itemstyle = active ? {color: '#fff'} : {color: '#a1a1a1'};
			
			dayline.push(
				<TouchableOpacity key={i} onPress={ () => {_onPressHeader(props, 'day', i + 1, page.duration)} } 
					style={[cellstyle, Styles.calendarNav]}>
					<View>
						<Text style={[itemstyle, {fontSize: pt_dow, alignSelf: 'center'}]}>{dow}</Text>
						<Text style={[itemstyle, {fontSize: pt_num, fontWeight: 'bold', alignSelf: 'center'}]}>{daynum}</Text>
					</View>
				</TouchableOpacity>
			);
			if (!isGeneric) date.addDays(1);
		}
		dayline = (
			<View style={{flex: 7, flexDirection: 'row'}}>
				{dayline}
			</View>
		);
		if (Platform.OS == 'web') {		// patch so web version day selection remains same size when a day is clicked
			dayline = (
				<div className="dayline">
				{dayline}
				</div>
			)
		}
	}
	
	return (
		<View key={key} style={{marginBottom: 25}}>
			{topline}
			{dayline}
		</View>
	)
}

function generateCustomBlock(page, props, key, state) {
	
	if (page.type !== 'calendar') return null;
	var dt = Storage.local_getValue('return-home', '');

//	console.log('Calendar::generateCustomBlock', page);
	
	if (typeof page.duration === 'object' && page.duration !== null) {
		return _onShowHeader(props, page, key, dt);
	}
	
	var dt = Storage.local_getValue('return-home', '');
	var showdate = (typeof state.showdate === 'undefined') ? false : state.showdate;
	if (dt !== '' && !showdate) {
		var date = XDate(dt);
		var datetext = date.toString('dddd, MMMM d, yyyy');
		return (
			<View key={key} >
				<View style={{marginTop: 20, flex: 1, flexDirection: 'row', justifyContent: 'flex-start'}}>
					<Text>
						<Text style={{fontSize: 16}}>You returned home on </Text>
						<Text style={{fontSize: 16, fontWeight: 'bold'}}>{datetext}</Text>
						<Text style={{fontSize: 16}}>.  </Text>
						<Text style={{fontSize: 16, color: 'blue'}} onPress={() => {_onPressChange(props)}}>(change)</Text>
					</Text>
				</View>
			</View>
		);
	}

	// start of calendar is today - 4weeks. 
	var start;
	if (dt == '') {
		start = new XDate();
		dt = start.toString('yyyy-MM-dd');
	} else {
		start = XDate(dt);
	}
	var minDate = start.addDays(-28).toString('yyyy-MM-dd');

	var markedDates = {};
	markedDates[dt] = {selected: true, selectedColor: theme.color};
	return (
		<View key={key} >
			<View style={{marginTop: 20}}>
				<Text style={{fontSize: 16}}>Please select the date you returned home</Text>
			</View>
			<Calendar style={{borderColor: '#999', borderWidth: 1, width: 360, marginTop: 20, marginBottom: 10, alignSelf:'center'}}
				current={dt}
				markedDates={markedDates}
				minDate={minDate}
				hideExtraDays={false}
				disableMonthChange={false}
				renderArrow={(direction) => {return _drawArrow(direction)}}
				onDayPress={(day) => {_onPressDay(day, props)}}
				{...props}
			/>
		</View>
	);
}

function _getEntryForEntireWeek(category, caption) {
	let i;
	for(i = 0; i < crossReference.length; i++) {
		let node = crossReference[i].duration;
		if (typeof node.category !== 'undefined' && node.category == category) {
			if (node.finish > node.start && node.caption == caption) {
				return crossReference[i].index + 1;
			}
		}
	}

	for(i = 0; i < crossReference.length; i++) {
		if (crossReference[i].duration.week == caption) {
			if (crossReference[i].duration.finish > crossReference[i].duration.start) {
				return crossReference[i].index + 1;
			}
		}
	}
	return false;

}

function getPage(type, value, duration) {
	if (type !== 'day') {
		// going to an entry that covers the entire week?
		let page = _getEntryForEntireWeek(type, value);
		if (page !== false) return page;

		// newly selected week also contains day - choose the appropriate day
		if (typeof duration.day !== 'undefined') {
			var dow = duration.day - (duration.week - 1) * 7;
			var newdaynum = (value - 1) * 7 + dow;
			for(let i = 0; i < crossReference.length; i++) {
				if (crossReference[i].duration.week == value && crossReference[i].duration.start == newdaynum ) {
					return crossReference[i].index + 1;
				}
			}
		}
		
		// find first entry for the week (should include today's date)
		for(let i = 0; i < crossReference.length; i++) {
			if (crossReference[i].duration.week == value) {
				return crossReference[i].index + 1;
			}
		}
		
		// Added for week_nav structure
		for(let i = 0; i < crossReference.length; i++) {
			if (crossReference[i].duration.caption == value && crossReference[i].duration.category == type) {
				return crossReference[i].index + 1;
			}
		}
		return crossReference[0].index + 1;
	} else {
		let daynum = (duration.week - 1) * 7 + value;
		for(let i = 0; i < crossReference.length; i++) {
			if (daynum == crossReference[i].duration.start) {
				return crossReference[i].index + 1;
			}
		}
		return 1;
	}
}

function clear() {
	Storage.local_setValue('return-home', '');
}


export default {
	load,
	generateCustomBlock,
	getPage,
	clear,
}