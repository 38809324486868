import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Slider,
  Dimensions,
  Image,
} from 'react-native';

//import MediaLibrary from  '../content/MediaLibrary';
import { Audio } from 'expo-av';
import Styles from '../theme/Styles';
import PaiSlider from '../components/slider';
import Storage from '../components/Storage';
import ProcedureData from '../components/ProcedureData';
import Resource from '../components/Resource';

import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import icoMoonConfig from '../theme/icomoon.json';
const expoAssetId = require("../theme/icomoon.ttf");
const IconMoon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', expoAssetId);

// Playback frame:
//		thumbnail
//		progress bar
//		stop / pause / play 
//		mute / unmute

export default class Playback extends Component {

  constructor(props){
      super(props);
//      this._goToURL = this._goToURL.bind(this);
		this.index = 0;
		this.isSeeking = false;
		this.shouldPlayAtEndOfSeek = false;
		this.playbackInstance = null;
		this.state = {
		  showVideo: false,
//		  playbackInstanceName: LOADING_STRING,
//		  loopingType: LOOPING_TYPE_ALL,
		  muted: false,
		  playbackInstancePosition: null,
		  playbackInstanceDuration: null,
		  shouldPlay: false,
		  isPlaying: false,
		  isBuffering: false,
		  isLoading: true,
		  fontLoaded: false,
		  shouldCorrectPitch: true,
		  volume: 1.0,
		  rate: 1.0,
		  imageTicker: false,
//		  videoWidth: DEVICE_WIDTH,
//		  videoHeight: VIDEO_CONTAINER_HEIGHT,
		  poster: false,
		  useNativeControls: false,
		  fullscreen: false,
		  throughEarpiece: false
		};
		this.images = {};
		this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
 	ProcedureData.loadQueuedImages(this);
 }

  _onPlaybackStatusUpdate = status => {
    if (status.isLoaded) {
      this.setState({
        playbackInstancePosition: status.positionMillis,
        playbackInstanceDuration: status.durationMillis,
        shouldPlay: status.shouldPlay,
        isPlaying: status.isPlaying,
        isBuffering: status.isBuffering,
        rate: status.rate,
        muted: status.isMuted,
        volume: status.volume,
//        loopingType: status.isLooping ? LOOPING_TYPE_ONE : LOOPING_TYPE_ALL,
        shouldCorrectPitch: status.shouldCorrectPitch
      });
		this.props.onprogress(status.positionMillis);
	  
    } else {
      if (status.error) {
        console.log(`FATAL PLAYER ERROR: ${status.error}`);
      }
    }
  };


  componentDidMount() {
	this._isMounted = true;
	if (this._isMounted) {
		if (this.playbackInstance != null) {
			this.playbackInstance.setOnPlaybackStatusUpdate(null);
			if (this.state.isPlaying) {
				this.playbackInstance.stopAsync();		// stop playback
			}
//			this.playbackInstance.unloadAsync();
//			this.playbackInstance = null
		}
	};
}

	componentWillUnmount() {
		this._isMounted = false;
	}
  
	// https://docs.expo.io/versions/latest/sdk/audio/
	
	// will not work in Chrome on Android:
	async _performPlayback() {
		this.playbackInstance = new Audio.Sound();

		let audio = ProcedureData.getAudio();
		if (!audio.length) return;	// no audio
//		let filename = audio[0].filename[2];		// flac
		let filename = audio[0].filename[1];		// mp3
//		let filename = audio[0].filename[0];		// wav
		let previewmode = Resource.getPreviewMode();
		let api_url = Storage.getAPIurl(previewmode);
		filename = api_url + '/download/' + filename;

		// load audio
		try {
		  await this.playbackInstance.loadAsync({ uri: filename });
		  this.setState({isLoading: false});
		  this.playbackInstance.playAsync();		  // Your sound is playing!
		  this.playbackInstance.setOnPlaybackStatusUpdate(this._onPlaybackStatusUpdate);
		} catch (error) {
			console.log(error);			  // An error occurred!
		}

	}

 _onSeekSliderValueChange = value => {
    if (this.playbackInstance != null && !this.isSeeking) {
      this.isSeeking = true;
      this.shouldPlayAtEndOfSeek = this.state.shouldPlay;
      this.playbackInstance.pauseAsync();
    }
  };

  _onSeekSliderSlidingComplete = async value => {
    if (this.playbackInstance != null) {
      this.isSeeking = false;
      const seekPosition = value * this.state.playbackInstanceDuration;
      if (this.shouldPlayAtEndOfSeek) {
        this.playbackInstance.playFromPositionAsync(seekPosition);
      } else {
        this.playbackInstance.setPositionAsync(seekPosition);
      }
    }
  };

  _getSeekSliderPosition() {
    if (
      this.playbackInstance != null &&
      this.state.playbackInstancePosition != null &&
      this.state.playbackInstanceDuration != null
    ) {
      return (
        this.state.playbackInstancePosition /
        this.state.playbackInstanceDuration
      );
    }
    return 0;
  }

  _renderImage(imagename, playerwidth) {
	  if (imagename !== '') {
		  let resource = ProcedureData.getImageInfo(imagename);
		  let aspect = resource.ht / resource.wd;
		  var nht = playerwidth * aspect;
		  let imagedata = ProcedureData.getQueuedImage(this, resource);
		  return (
			<Image 
				key={this.state.imageTicker}
				source={imagedata} 
				style={{width: playerwidth, height:nht}} 
			/>
		  )
	  }
	  return null;
  }
  
  _onPlayPausePressed = () => {
    if (this.playbackInstance != null) {
      if (this.state.isPlaying) {
        this.playbackInstance.pauseAsync();
      } else {
        this.playbackInstance.playAsync();
      }
    } else {
		 this._performPlayback()
	}
  };

  _onStopPressed = () => {
    if (this.playbackInstance != null) {
      this.playbackInstance.stopAsync();
    }
  };
  
  
  _renderButton(iconname, onpress) {
	  if (iconname == '') {
		  iconname = this.state.isPlaying ? 'pause2' : 'play3';
	  }
	  return (
		<TouchableOpacity onPress={onpress}>
			<IconMoon name={iconname} size={34} style={{marginRight: 5}} />
		</TouchableOpacity>
	  )
  }

  // borderColor: '#009', borderWidth: 1,
  render() {
		const {block} = this.props;
		var {width} = Dimensions.get('window');
		var playerwidth = width > 800 ? 760 : width - 40;
		this.filename = block.filename;
		var imagename = typeof block.name !== 'undefined' ? block.name : '';
		
		return (
			<View style={[Styles.playerContainer,{width: playerwidth}]}>
				{this._renderImage(imagename, playerwidth)}
				<View style={{flex: 1, flexDirection: 'row'}}>
					{this._renderButton('', this._onPlayPausePressed)}
					{this._renderButton('stop2', this._onStopPressed)}
					<View style={{paddingLeft:20}}>
						<PaiSlider style={{width: playerwidth - 100, height: 40}}
							value={this._getSeekSliderPosition()}
							onValueChange={this._onSeekSliderValueChange}
							onSlidingComplete={this._onSeekSliderSlidingComplete}
						/>
					</View>
				</View>
			</View>
		);
	}

}

/*

   _onMutePressed = () => {
    if (this.playbackInstance != null) {
      this.playbackInstance.setIsMutedAsync(!this.state.muted);
    }
  };
*/