// Based on  https://moduscreate.com/blog/expanding-and-collapsing-elements-using-animations-in-react-native/

import React from "react";
import {
  Component,
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  TouchableHighlight,
  Animated,
} from "react-native"; //Step 1
import Styles from "../theme/Styles";
import Expandable from "../components/Expandable";
import FakeHtmls from "../components/FakeHtml";
import Collapsible from "../components/Collapsible";

class Panel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //Step 3
      expanded: props.expanded || false,
      animated: false,
      hilite: props.hilite || false,
      //			counter		: 0,
    };

    this.onAnimationEnd = this.onAnimationEnd.bind(this);
    this.redraw = this.redraw.bind(this);
    this.refresh = this.refresh.bind(this);
    this.position = {};
    this.arrow = undefined;
    this.collapsible = undefined;
    this.header_element = React.createRef();
  }

  savePosition(pos) {
    this.position = pos;
  }

  resetItem() {
    if (typeof this.arrow === "object") {
      this.arrow.resetItem(true);
    }
    this.setState({
      hilite: false,
      animated: false,
      expanded: false,
    });
  }

  componentDidMount() {}

  refresh() {
    /*		if (typeof this.header_element.current != 'undefined' && this.header_element.current !== null) {
			this.header_element.current.setState({
			    measuring: false,
				measured: false,
			});
		} */
    if (typeof this.collapsible != "undefined") {
      this.collapsible.setState({
        measuring: false,
        measured: false,
      });
    }
  }

  redraw() {
    if (typeof this.collapsible != "undefined") {
      this.collapsible.redraw();
    }
  }

  toggle() {
    let newexpanded = !this.state.expanded;
    if (typeof this.arrow !== "undefined" && this.arrow !== null) {
      this.arrow.setState({
        expanded: newexpanded,
        animated: true,
      });
    }
    this.setState({
      expanded: newexpanded,
    });
    // let parent know this is toggled - will untoggle other panels
    this.props.onChange({
      id: this.props.id,
      expanded: newexpanded,
      group: this.props.page.group,
      parentgroup:
        typeof this.props.parentgroup === "undefined"
          ? 0
          : this.props.parentgroup,
      parentindex:
        typeof this.props.parentindex === "undefined"
          ? 0
          : this.props.parentindex,
    });
  }

  collapse() {
    this.setState({ hilite: false, animated: true, expanded: false });
    if (typeof this.arrow !== "undefined" && this.arrow !== null) {
      this.arrow.setState({
        expanded: false,
        animated: true,
      });
    }
  }

  _addSGML(txt) {
    return txt?.replace(/&/gi, "&amp;").replace(/</gi, "&lt;");
  }

  _formattedText(text, page) {
    let fmttext = FakeHtmls.formattedText(text, page.size);
    let _style = {
      paddingTop: 7,
      paddingBottom: 7,
    };
    if (page.group == 10017) _style.marginLeft = 20;

    let dot = {
      backgroundColor: page.color,
      width: 10,
      height: 10,
      borderBottomLeftRadius: 50,
      borderBottomRightRadius: 50,
      borderTopLeftRadius: 50,
      borderTopRightRadius: 50,
      position: "absolute",
      marginTop: 8,
      marginLeft: -20,
    };

    if (page.centre) _style.alignSelf = "center";
    return (
      <View style={_style}>
        {page.group == 10017 && <View style={dot}></View>}
        {fmttext}
      </View>
    );
  }

  onAnimationEnd() {
    this.props.onAnimationEnd({
      id: this.props.id,
      expanded: this.state.expanded,
      group: this.props.page.group,
      parentgroup:
        typeof this.props.parentgroup === "undefined"
          ? 0
          : this.props.parentgroup,
      parentindex:
        typeof this.props.parentindex === "undefined"
          ? 0
          : this.props.parentindex,
    });
  }

  _generateHeader(onsameline, text1, text2, page) {
    let result;
    if (typeof text2 == "object") {
      // This is an `Element` object - process all children
      if (this.props.onRenderElement) {
        return this.props.onRenderElement(text1, text2, onsameline);
      } else {
        text2 = "(object error)";
      }
    }
    if (onsameline) {
      result = this._formattedText(text1 + " " + text2, page);
    } else {
      result = this._formattedText(text2, page);
    }
    return result;
  }

  // add text/image to the top of the panel
  _onpanelheader(page) {
    if (this.props.onPanelHeader) {
      return this.props.onPanelHeader({ page });
    }
    return null;
  }

  // whether it has a dropdown or not, this must handle toggle events
  _drawLineItem(page, itemkey, hilite) {
    let override;
    let hasSubtext = typeof page.subtext !== "undefined";
    let onsameline =
      typeof page.sameline === "undefined" ? false : page.sameline;
    let hasDropdown = typeof page.children !== "undefined";
    let isbold = typeof page.bold === "undefined" ? false : page.bold;
    let depth = typeof page.depth === "undefined" ? 1 : page.depth;
    let basestyle;
    if (depth == 2) {
      // these have padding
      basestyle = Styles.dropdownTop2;
    } else {
      basestyle = Styles.dropdownTop;
    }

    if (hilite) {
      if (depth == 2) {
        override = Styles.dropdownHilite2;
      } else {
        override = Styles.dropdownHilite;
      }
    } else {
      override = {};
    }

    let text1 =
      page.text === "Preparation"
        ? "Arrival"
        : page.text === "What happens?"
        ? "The Procedure"
        : page.text;
    if (typeof page.texthtml === "undefined" || !page.texthtml) {
      text1 = this._addSGML(text1);
      if (isbold) text1 = "<b>" + text1 + "</b>";
    }

    var headerimage = this._onpanelheader(page);
    if (hasSubtext) {
      // header has two types of text
      var text2 = page.subtext;
      var subhtml = typeof page.subhtml !== "undefined" ? page.subhtml : false;
      if (typeof text2 != "object") {
        if (!subhtml) text2 = this._addSGML(text2);
        text2 = "<i>" + text2 + "</i>";
      }
      var headerstuff = this._generateHeader(onsameline, text1, text2, page);
      // paddingRight is to allow space for the dropdown arrow. Default is 15, from basestyle
      if (onsameline) {
        return (
          <View style={[basestyle, override, { paddingRight: 35 }]}>
            {headerimage}
            {headerstuff}
            {hasDropdown && (
              <Expandable
                style={[Styles.pagearrow, { width: 35 }]}
                id={itemkey}
                ref={(ref) => {
                  this.arrow = ref;
                  return true;
                }}
                size={22}
                color={"#999"}
              />
            )}
          </View>
        );
      } else {
        return (
          <View style={[basestyle, override]}>
            <View style={{ paddingRight: 20 }}>
              {headerimage}
              {this._formattedText(text1, page)}
              {hasDropdown && (
                <Expandable
                  style={Styles.pagearrow}
                  id={itemkey}
                  ref={(ref) => {
                    this.arrow = ref;
                    return true;
                  }}
                  size={22}
                  color={"#999"}
                />
              )}
            </View>
            <View>{headerstuff}</View>
          </View>
        );
      }
    } else {
      return (
        <View style={[basestyle, override]}>
          <View style={{ paddingRight: 20 }}>
            {headerimage}
            {this._formattedText(text1, page)}
            {hasDropdown && (
              <Expandable
                style={Styles.pagearrow}
                id={itemkey}
                ref={(ref) => {
                  this.arrow = ref;
                  return true;
                }}
                size={22}
                color={"#999"}
              />
            )}
          </View>
        </View>
      );
    }
  }

  _onLayout(event) {
    if (this.props.onLayout) {
      this.props.onLayout(event, this);
    }
  }

  render() {
    let basestyle;
    let header = this._drawLineItem(
      this.props.page,
      this.props.id,
      this.state.hilite
    );

    let depth =
      typeof this.props.page.depth === "undefined" ? 1 : this.props.page.depth;
    if (depth == 2) {
      basestyle = Styles.dropdownBody2;
    } else {
      basestyle = Styles.dropdownBody;
    }

    return (
      <View
        style={this.props.style}
        onLayout={(event) => this._onLayout(event)}
      >
        <TouchableOpacity
          onPress={() => this.toggle()}
          ref={this.header_element}
        >
          {header}
        </TouchableOpacity>

        <Collapsible
          style={Styles.dropdownContent}
          onRef={(ref) => (this.collapsible = ref)}
          align={"top"}
          collapsed={!this.state.expanded}
          onAnimationEnd={() => this.onAnimationEnd()}
        >
          <View style={basestyle}>{this.props.body}</View>
        </Collapsible>
      </View>
    );
  }
}
export default Panel;
