import React, { Component } from 'react';
import {
	Text,
	View,
	Image,
	ImageBackground,
	TouchableOpacity,
	Picker,
	ScrollView,
	Dimensions,
	Platform,
	Switch,
	Alert,
} from 'react-native';

import Styles, { HeaderBar, documentTitle } from '../theme/Styles';
import { withNavigation, DrawerActions } from 'react-navigation';
import Storage from '../components/Storage';
import Frame from '../components/Frame';
import Country from '../theme/country.json';
import Resource from '../components/Resource';

import RNPickerSelect from 'react-native-picker-select';
import ProcedureData from '../components/ProcedureData';
import Feedbacks from '../components/Feedback';
import ProcedureDownload from '../components/ProcedureDownload';
import GlobalSettings from '../components/GlobalSettings';
import PaiCalendar from '../components/PaiCalendar';
import NavigationService from '../components/NavigationService';
import Emergency from '../components/Emergency';

import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import icoMoonConfig from '../theme/icomoon.json';
const expoAssetId = require('../theme/icomoon.ttf');
const Icon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', expoAssetId);

/*
* Settings page
-   reset clear switches on page load
- select temperature units
- select weight units
- select country - only list countries that have emergency numbers, and only for generic guides
- show introductory pages  ****
- alert on incomplete activity ****
- clear settings and select another procedure. -- not on web version
*/

class SettingsPage extends Component {

	constructor(props) {
		super(props);
		this._resetSwitches = this._resetSwitches.bind(this);
		let country = Storage.local_getValue('country', 'us');
		let flag = Storage.local_getValue('introduction', '0') != '0';
		this.state = {
			width: 0,
			country_selected: country,
			hasSurgery: false,
			hasReturnDate: false,
			clear_date: false,
			clear_surgery: false,
			clear_history: false,
			clear_all: false,
			show_intro: flag,
			isGeneric: false,
		}
		this.hasSurgery = false;
		this.hasReturnDate = false;
		this.switches = this.switches.bind(this);
		this._alert3 = this._alert3.bind(this);
		this._alert4 = this._alert4.bind(this);
		this.emergency = Emergency.retrieveList();		// only when generic
		this.detectedProduct = Resource.detectedProduct();
		this._isMounted = false;
	}

	static navigationOptions = ({ navigation }) => {
		return HeaderBar(navigation, { title: 'Settings' });
	}

	static language = 'eng';

	changeSelect = (itemValue) => {
		this.setState({ country_selected: itemValue });
	}

	// scan the entire document for the special blocks
	_scanPage(page) {
		if (page.type === 'radio') {
			if (page.key === 'surgery') {
				this.hasSurgery = true;
			}
		} else if (page.type === 'calendar') {
			this.hasReturnDate = true;
		}
		if (typeof page.children !== 'undefined') {
			for (let i = 0; i < page.children.length; i++) {
				this._scanPage(page.children[i]);
			}
		}
	}

	// Settings page is being loaded - reset 'clear' switches
	// _resetSwitches(params) {
	// 	let { action, state, result } = params;
	// 	if (action.type == 'Navigation/NAVIGATE' && action.routeName == 'ShareStack') {
	// 		this.setState({
	// 			clear_date: false,
	// 			clear_surgery: false,
	// 			clear_history: false,
	// 			clear_all: false,
	// 		});
	// 	}
	// }

	componentDidMount() {
		Storage.on('pageBlur', this._resetSwitches);
		// is there a radiobutton/surgery option
		let content_text = ProcedureData.contentText();
		this.hasSurgery = false;
		this.hasReturnDate = false;

		let product = Storage.local_getValue('procedure', null);
		let info = GlobalSettings.getProductInfo(product);
		let isGeneric = info.generic > 0;

		for (let i = 0; i < content_text.content.length; i++) {
			let page = content_text.content[i];
			this._scanPage(page);
		}
		this.setState({
			hasSurgery: this.hasSurgery,
			hasReturnDate: this.hasReturnDate,
			isGeneric: isGeneric,
		});

		this._isMounted = true;
		if (this._isMounted) {
			Storage.off('pageBlur', this._resetSwitches);
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	// perform the desired actions
	componentDidUpdate(prevProps, prevState) {
		if (this.state.clear_all || this.state.clear_date || this.state.clear_history) {
			PaiCalendar.clear(); // clear date, no redirect
		}
		if (this.state.clear_all || this.state.clear_surgery || this.state.clear_history) {
			Storage.local_removeValue('surgery'); // clear surgery, no redirect (do this even if surgery radio buttons are not used)
		}
		if (this.state.clear_all || this.state.clear_history) {
			Feedbacks.clear();						// clear feedback history, and guid
			Storage.local_removeCheckboxes();		// clear checkbox history, 
		}
		if (this.state.clear_all) {
			ProcedureDownload.clear();
			GlobalSettings.clear(); 	// remove master.json
			NavigationService.clear();
			ProcedureData.clear();
		}
		if (this.state.clear_history) {
			this.props.navigation.navigate('content', { page: 4 });
		} else if (this.state.clear_all) {
			this.props.navigation.navigate('content', { page: 1 });
			Storage.trigger('restart', {});
		}

		let old_country = Storage.local_getValue('country', '');
		if (old_country !== this.state.country_selected) {
			Storage.local_setValue('country', this.state.country_selected);
			ProcedureData.setEmergencyNumber();
		}
		let flag = Storage.local_getValue('introduction', '0') != '0';
		if (flag != this.state.show_intro) {
			Storage.local_setValue('introduction', this.state.show_intro ? '1' : '0')
		}

	}

	// only when generic selected - used to get the Emergency numbers for the country
	_settingCountry(wd) {
		let list = [];
		for (let cc in this.emergency) {
			if (typeof Country[cc] !== 'undefined') {
				let value = Country[cc];
				let name = value.flag + ' ' + value.name.eng;
				list.push({ value: cc, label: name, sort: value.name.eng });
			}
		}
		list.sort(function (a, b) {
			return ((a.sort == b.sort) ? 0 : ((a.sort > b.sort) ? 1 : -1));
		});
		list.push({ value: '??', label: '(other country)' });
		return (
			<View style={{ width: wd, flex: 1, flexDirection: 'column' }}>
				<Text style={{ marginTop: 20, marginLeft: 20, fontSize: 22, color: '#000' }}>Country</Text>
				<View style={{ marginLeft: 20 }}>
					<RNPickerSelect textInputProps={{ fontSize: 22 }}
						value={this.state.country_selected}
						onValueChange={this.changeSelect}
						items={list}
					/>
				</View>
			</View>
		)
	}

	switches(value, key) {
		let states = {};
		states[key] = value;
		this.setState(states);
	}

	_alert3(value, key) {
		// also clears return date, selected surgery 
		if (value) {
			if (Platform.OS === 'web') {
				if (confirm("Clear History\n\nThis will clear your history, including any checkboxes you have checked")) {
					this.switches(value, key);
				}
			} else {
				Alert.alert(
					'Clear History',
					'This will clear your history, including any checkboxes you have checked',
					[
						{
							text: 'Cancel',
							onPress: () => {
								//						this.setState({clear_history: false});
							},
							style: 'cancel',
						}, {
							text: 'OK',
							onPress: () => this.switches(value, key)
						},
					],
					{ cancelable: false },
				);
			}
		} else {
			this.switches(value, key)
		}
	}

	_alert4(value, key) {
		// also clears return date, selected surgery, and checkboxes
		if (value) {
			if (Platform.OS === 'web') {
				if (confirm("Clear Everything\n\nThis will clear all your data, restart, and ask you to select a procedure")) {
					this.switches(value, key);
				}
			} else {
				Alert.alert(
					'Clear Everything',
					'This will clear all your data, restart, and ask you to select a procedure',
					[
						{
							text: 'Cancel',
							onPress: () => {
								//						this.setState({clear_all: false});
							},
							style: 'cancel',
						}, {
							text: 'OK',
							onPress: () => this.switches(value, key)
						},
					],
					{ cancelable: false },
				);
			}
		} else {
			this.switches(value, key)
		}
	}

	_settingRadio(wd, key, caption, confirm) {
		let checked = true;
		let style = {
			width: wd,
			flex: 1,
			flexDirection: 'row',
			alignItems: 'center',
			justifyContent: 'space-between',
			marginTop: 10,
			marginBottom: 10,
			marginLeft: 25,
			...Platform.select({
				web: {
					marginRight: 15,
				}
			}),
		};
		// web implementation of Switch does not have onChange, so must use onValueChange
		return (
			<View style={style}>
				<Text style={{ color: '#000', fontSize: 20 }}>{caption}</Text>
				<Switch
					value={this.state[key]}
					onValueChange={(value) => { confirm(value, key) }}
				/>
			</View>
		);
	}

	// display:
	//      program version
	//      data version
	//      product name
	// to do: redraw on change of orientation , alignSelf: 'center'
	render() {
		var { height, width } = Dimensions.get('window');
		let wd = width - 50;
		if (wd > 400) wd = 400;
		let allowChange = !(this.detectedProduct);
		return (
			<View style={{height: '100%', backgroundColor: '#d0e5fa'}}>
			<ScrollView style={{ flex: 1, flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto' }} >
				<View>{this.state.isGeneric && this._settingCountry(wd)}</View>
				<View>{this._settingRadio(wd, 'show_intro', 'Show Introductory Pages', this.switches)}</View>
				<View>{this.state.hasReturnDate && this._settingRadio(wd, 'clear_date', 'Clear Return Home Date', this.switches)}</View>
				<View>{this.state.hasSurgery && this._settingRadio(wd, 'clear_surgery', 'Clear Selected Surgery', this.switches)}</View>
				<View>{this._settingRadio(wd, 'clear_history', 'Clear History', this._alert3)}</View>
				{allowChange && <View>{this._settingRadio(wd, 'clear_all', 'Clear Everything', this._alert4)}</View>}
				{allowChange && <Text style={{ color: '#000', marginLeft: 20 }}>Clearing everything will allow you to choose another procedure</Text>}
			</ScrollView>
			</View>
		);
	}
}

export default withNavigation(SettingsPage);
