import React, { Component } from "react";

import {
  Platform,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Image,
  StyleSheet,
  AppState,
  CheckBox,
  TextInput,
  Button,
} from "react-native";
import _, { debounce } from "lodash";
import * as Linking from "expo-linking";

import Playback from "../components/Playback";
import Styles, { getImage } from "../theme/Styles";
// import Styles from '../theme/Styles';
import Icon from "react-native-vector-icons/FontAwesome5";
import { withNavigation, NavigationActions } from "react-navigation";
import Storage from "../components/Storage";
import ProcedureData from "../components/ProcedureData";
import NavigationService from "../components/NavigationService";

import Feedbacks from "../components/Feedback";
import Specials from "../components/Special";
import PaiCalendar from "../components/PaiCalendar";
import PaiTable from "../components/PaiTable";
import FakeHtmls from "../components/FakeHtml";
import WarnOnBlur from "../components/WarnOnBlur";
import PaiMarquee from "../components/PaiMarquee";
import RadioForm, {
  RadioButton,
  RadioButtonInput,
  RadioButtonLabel,
} from "../components/SimpleRadioButton";
import Panel from "../components/Panel";
import Watch from "../components/Watch";
import accordion from "../components/accordion";
import { WebView } from "react-native-webview";
import { DropDownHolder, DropdownType } from "./DropDownHolder";

import { createIconSetFromIcoMoon } from "@expo/vector-icons";
import icoMoonConfig from "../theme/icomoon.json";
import CDropDown from "./CDropDown";
const expoAssetId = require("../theme/icomoon.ttf");
const IconMoon = createIconSetFromIcoMoon(
  icoMoonConfig,
  "icomoon",
  expoAssetId
);

// borderWidth: StyleSheet.hairlineWidth

let marginTop;
let theme = StyleSheet.flatten(Styles.theme);

class PaiPage extends Component {
  constructor(props) {
    super(props);
    var { width, height } = Dimensions.get("window");

    this.state = {
      dropCount: 0,
      activeTab: "",
      lastPagenum: -1,
      lastDrop: -1,
      lastTab: "",
      lastWidth: -1,
      lastBackground: null,
      imageTicker: false,
      imageTickerLast: false,
      subimage: false,
      background: null,
      width: width,
      windowWidth: width,
      windowHeight: height,
      windowWidthLast: false,
      showdate: false,
      specials: {},
      appstate: AppState.currentState,
      agree: false,
      text: "",
      // weekTab: true
    };
    //		this.changeDimensions = this.onChange.bind(this);
    this.updateDimensions = debounce(this.onChange.bind(this), 100);
    this._changeDate = this._changeDate.bind(this);
    this._selectDate = this._selectDate.bind(this);
    this._selectPage = this._selectPage.bind(this);
    this.onChangeDropdown = this.onChangeDropdown.bind(this);
    this.onRenderElement = this.onRenderElement.bind(this);
    this.assignReference = this.assignReference.bind(this);
    this.uncheckCount = 0;
    this.references = {}; // An array of <Accordion>. This tracks the state of each dropdown - position and whether it is expanded or not.
    this.images = {};
    this._isMounted = false;
  }

  // called if change in state or props
  static getDerivedStateFromProps(props, state) {
    let newpage = typeof props.page === "object" ? props.page.pagenum : -1;
    let changed = false;
    let result = {};
    let activetab = false;
    if (newpage !== state.lastPagenum) {
      result.page = props.page;
      result.lastPagenum = props.page.pagenum;
      result.dropCount = -1;
      result.lastDrop = -1;
      result.width = state.width;
      result.height = state.height;
      result.lastWidth = state.width;
      result.windowWidth = state.windowWidth;
      result.windowHeight = state.windowHeight;
      result.windowWidthLast = state.windowWidth;
      result.specials = Specials.load(props.page);
      activetab = NavigationService.getActiveTab(props.page.hash);
      if (activetab !== false) result.activeTab = activetab;
      changed = true;
    }
    if (state.dropCount != state.lastDrop) {
      result.dropCount = state.dropCount;
      result.lastDrop = state.dropCount;
      changed = true;
    }
    if (state.activeTab != state.lastTab) {
      result.activeTab = state.activeTab;
      result.lastTab = state.activeTab;
      changed = true;
    }
    if (activetab === false)
      activetab = NavigationService.getActiveTab(props.page.hash);
    let background = PaiPage._scanforbanner(props.page, activetab);
    if (background != state.lastBackground) {
      result.background = background;
      result.lastBackground = background;
      changed = true;
    }
    if (state.imageTicker != state.imageTickerLast) {
      result.imageTicker = state.imageTicker;
      result.imageTickerLast = state.imageTicker;
      changed = true;
    }

    if (
      state.width != state.lastWidth ||
      state.windowWidth != state.windowWidthLast
    ) {
      result.width = state.width;
      result.height = state.height;
      result.lastWidth = state.width;
      result.windowWidth = state.windowWidth;
      result.windowHeight = state.windowHeight;
      result.windowWidthLast = state.windowWidth;
      changed = true;
    }
    return changed ? result : null;
  }

  _checkForGroup(groups, page) {
    if (page.type === "lineitem") {
      groups[page.group] = 1;
    }
    if (page.children) {
      for (let i = 0; i < page.children.length; i++) {
        this._checkForGroup(groups, page.children[i]);
      }
    }
  }

  activeGroups(page) {
    let groups = {};
    this._checkForGroup(groups, page);
    return groups;
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.page.pagenum != nextState.page.pagenum) {
      let groups = this.activeGroups(this.state.page);
      for (let group in groups) {
        let node = this.references[group];
        if (typeof node !== "undefined" && node !== null) {
          this.references[group].unload(); // unselect the current item - no animation
        }
      }
    }
    return true;
  }

  onLayout = (e) => {
    var { width, height } = Dimensions.get("window");
    this.setState({
      width: e.nativeEvent.layout.width,
      height: e.nativeEvent.layout.height,
      windowWidth: width,
      windowHeight: height,
    });
  };

  onChangeCheckBok = (e) => {
    let agree = !this.state.agree;
    this.setState({ agree });
  };

  onPressAgree = (e) => {
    this.setState({ agree: true });
    this.props.navigation.navigate("content", { page: 4 });
  };

  // this will update the starting position of the text
  onChange(object) {
    var { width, height } = Dimensions.get("window");
    this.setState({
      changes: 1,
      windowWidth: width,
      windowHeight: height,
      showdate: false,
    });
    // resize of background banner is handled by Content.js
  }

  componentDidMount() {
    Dimensions.addEventListener("change", this.updateDimensions);
    AppState.addEventListener("change", this._handleAppStateChange);

    this._isMounted = true;
    if (this._isMounted) {
      // Dimensions.removeEventListener('change', this.updateDimensions);
      // AppState.removeEventListener('change', this._handleAppStateChange);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  _handleAppStateChange = (nextAppState) => {
    this.setState({ appstate: nextAppState });
  };

  // run after render - to load background and other images
  componentDidUpdate(prevProps, prevState) {
    if (this.props.onBackground) {
      this.props.onBackground(this.state.background);
    }
    ProcedureData.loadQueuedImages(this);

    let prevpage = prevProps.page.pagenum ? prevProps.page.pagenum : -1;
  }

  Children(page, imagestyle = null) {
    if (typeof page.children != "undefined") {
      var pagenum = NavigationService.getCurrentPage();
      if (pagenum === 5 && page.caption === "Basics") {
        const pg = ProcedureData.getPageFromHash(page.children[0].target);
        return (
          <CDropDown
            contentdata={page.children.filter((v) => v.type === "link")}
            onItemClick={(item) => {
              this.link_visit(item.target, item.pn);
            }}
          />
        );
      }
      return page.children.map((value, i) => {
        return this.Block(value, i, imagestyle);
      });
    }
    return null;
  }

  DropDown(page) {
    // normally hidden
    if (typeof page.children != "undefined") {
      var drops;
      var special = typeof page.special !== "undefined" ? page.special : 0;
      if (page.type == "lineitem" && page.special > 0) {
        drops = Specials.addSpecialText(page, this.uncheckCount);
      } else {
        drops = page.children;
      }

      return drops.map((value, i) => {
        return this.Block(value, i);
      });
    }
    return null;
  }

  _formattedText(text, size) {
    let fmttext = FakeHtmls.formattedText(text, size);
    return (
      <View
        style={{
          paddingTop: 0,
          paddingBottom: 0,
          flex: 1,
          flexDirection: "column",
        }}
      >
        {fmttext}
      </View>
    );
  }

  link_click(target) {
    var pg = ProcedureData.getPageFromHash(target, -1);
    if (pg >= 0) {
      this.props.navigation.navigate("content", {
        page: pg + 1,
      });
      return;
    }
    // open webpage
    Linking.canOpenURL(target).then((supported) => {
      if (supported) {
        Linking.openURL(target);
      } else {
        console.log("Don't know how to open URI: " + target);
      }
    });
  }

  // ordinary block of html text, from v1.9.34, can also contain images
  _drawBlock(page) {
    let imgcount = 0;
    let maxwidth = 0;
    let childstuff = null;
    if (typeof page.children != "undefined") {
      for (let i = 0; i < page.children.length; i++) {
        if (page.children[i].type == "illustration") {
          let current_wd = page.children[i].width || 0;
          if (page.children[i].scale) {
            current_wd *= page.children[i].scale / 100;
          }
          if (current_wd > maxwidth) maxwidth = current_wd;
          imgcount++;
        }
      }
    }
    if (imgcount > 0) {
      if (!maxwidth) maxwidth = 200;
      childstuff = this.Children(page, {
        width: maxwidth,
        margin: 10,
        alignSelf: "center",
      }); // borderColor: '#090', borderWidth: 1,
      childstuff = (
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          {childstuff}
        </View>
      );
    }

    if (page.text != "Table of Contents") {
      let style = {
        // backgroundColor: 'white',
        padding: 15,
      };

      return (
        // title
        <View style={style}>
          {this._formattedText(page.text, page.size)}
          {childstuff}
        </View>
      );
    }
  }

  /*
	_showHint(page) {
		if (typeof page.hint === 'undefined') return null;
		return (
			<View><Text style={{fontStyle: 'italic', fontSize: 14, color: '#777'}}>{page.hint}</Text></View>
		);
	}
	*/

  _drawLink(page) {
    if (typeof page.target !== "undefined") {
      let icon = ProcedureData.getTargetIcon(page.target);
      if (!icon) icon = "doctor";

      return (
        <TouchableOpacity
          style={{ height: 80 }}
          onPress={() => {
            this.link_visit(page.target, page.pn);
          }}
        >
          <View style={Styles.pagelink}>
            <View style={Styles.linkiconLeft}>
              {page.img != undefined && (
                <Image source={page.img} style={Styles.button_image} />
              )}
              {page.img == undefined && (
                <IconMoon
                  name={icon}
                  color={"#fff"}
                  size={26}
                  style={{ textAlign: "center" }}
                />
              )}
            </View>
            <View style={Styles.linkBody}>
              {FakeHtmls.formattedText(page.text, page.size)}
              {
                // this._showHint(page)
              }
            </View>
            {
              //<View style={Styles.linkiconRight}>
              //	<IconMoon name={'circle-right'} color={'#999'} size={22} />
              //</View>
            }
          </View>
        </TouchableOpacity>
      );
    } else {
      return <View>external link: [{page.text}]</View>;
    }
  }

  link_visit(pagehash, pn = "") {
    if (!_.isUndefined(pagehash) && pagehash?.length != 4) {
      var pg = ProcedureData.getPageFromHash(pagehash);
      if (pg == 11) this.props.navigation.navigate("content", { page: 12 });
      else if (pg == 22) this.props.navigation.navigate("yourteam");
      else
        this.props.navigation.navigate("content", {
          page: pg + 1,
        });
    } else {
      if (pagehash == "1000")
        this.props.navigation.navigate("content", { page: pagehash });
      else if (pagehash == "2000")
        this.props.navigation.navigate("content", { page: 3 });
      else if (pn) this.props.navigation.navigate("content", { page: pn });
      else this.props.navigation.navigate("yourteam");
    }
  }

  _onRadioPress(value, radio_prop) {
    Storage.local_setValue(radio_prop.key, value);
    if (typeof radio_prop.target !== "undefined") {
      this.link_visit(radio_prop.target);
    }
  }

  _drawRadio(page) {
    let first = "";
    let radio_props = [];
    let group = "";
    let _default;
    let _default_index = 0;
    let current_index = 0;
    let parent_block = page[".parent"];
    for (let i = 0; i < parent_block.children.length; i++) {
      let child = parent_block.children[i];
      if (child.type === "radio") {
        if (first == "") {
          first = child.value;
          group = child.key;
          _default = Storage.local_getValue(group, "");
        }
        radio_props.push({
          label: child.text,
          value: child.value,
          key: child.key,
          target: child.target,
        });
        if (child.value == _default) _default_index = current_index;
        current_index++;
      }
    }
    if (page.value !== first) return null;

    return (
      <RadioForm
        radio_props={radio_props}
        initial={_default_index}
        labelHorizontal={true}
        buttonSize={12}
        buttonOuterSize={22}
        labelStyle={{ fontSize: 22 }}
        animation={true}
        selectedLabelColor={theme.color}
        labelColor={"#333"}
        selectedButtonColor={theme.color}
        buttonColor={theme.color}
        radioStyle={{
          flex: 1,
          flexDirection: "row",
          alignSelf: "flex-start",
          marginTop: 5,
          marginBottom: 5,
        }}
        onPress={(value, index, obj) => this._onRadioPress(value, obj)}
      />
    );
  }

  actionTab(hash) {
    // if (!this.state.weekTab)
    // 	this.setState({ weekTab: true });
    // else
    // 	this.setState({ weekTab: false });

    this.setState({ activeTab: hash });
    NavigationService.setActiveTab(this.props.page.hash, hash);
  }

  _drawTabButton(tabdata) {
    var x = tabdata.length;
    var pct = 100 / x + "%";
    let fontsize = this._width < 440 ? 18 : 22;

    return tabdata.map((value, i) => {
      var active_text = {};
      var active_container = {};
      if (
        value.hash === this.state.activeTab ||
        (!i && this.state.activeTab === "")
      ) {
        // active_text = Styles.tabtextActive;
        // active_container = Styles.tabtextcontainerActive;
      }

      let display = { width: pct, marginLeft: "auto", marginRight: "auto" };
      let caption = "";
      // if (this.state.weekTab) {
      // 	if (!i) {
      // 		display.display = 'none';
      // 		caption = tabdata[0].caption;
      // 	} else {
      // 		display.display = '';
      // 		caption = tabdata[1].caption;
      // 	}
      // } else {
      // 	if (!i) {
      // 		display.display = '';
      // 		caption = tabdata[0].caption;
      // 	} else {
      // 		display.display = 'none';
      // 		caption = tabdata[1].caption;
      // 	}
      // }

      return (
        <TouchableOpacity
          key={i}
          style={[Styles.tabbutton, display]}
          onPress={() => {
            this.actionTab(value.hash);
          }}
        >
          {/* <View style={[Styles.tabtextcontainer, active_container]}> */}
          <View style={[Styles.tabtextcontainer]}>
            {/* <Text style={[Styles.tabtext, active_text, { fontSize: fontsize }]}>{caption}</Text> */}
            <Text style={[Styles.tabtext, { fontSize: fontsize }]}>
              {caption}
            </Text>
          </View>
        </TouchableOpacity>
      );
    });
  }

  _getTabStructure(page) {
    let parent_block = page[".parent"];
    var tabdata = [];
    for (var i = 0; i < parent_block.children.length; i++) {
      if (parent_block.children[i].type === "tab") {
        tabdata.push({
          hash: parent_block.children[i].hash,
          caption: parent_block.children[i].caption,
        });
      }
    }
    return tabdata;
  }

  _drawTab(page) {
    if (!page[".parent"]) {
      return <Text>error: tab block not expected here</Text>;
    }

    var tabdata = this._getTabStructure(page);

    // is this the first tab? Ignore if not.
    if (page.hash !== tabdata[0].hash) {
      return null;
    }
    //		if (this.activeTab === '') this.activeTab = page.hash;

    return <View style={Styles.tabbar}>{this._drawTabButton(tabdata)}</View>;
  }

  // Children should only only be drawn if tab is active
  _drawTabContent(page) {
    var tabdata = this._getTabStructure(page);
    // is this tab active?
    var active_tab =
      this.state.activeTab === "" ? tabdata[0].hash : this.state.activeTab;

    if (active_tab === page.hash) {
      this.uncheckCount = Specials.countUncheckedOptions(page);
      return this.Children(page);
    }
    return null;
  }

  checkmark(hash, special, itemkey) {
    // if (special == 400 && !Storage.local_getCheckbox(hash, false)) {
    // 	if (!confirm('Are you sure you need to cancel?'))
    // 		return;
    // }

    var toggle = !Storage.local_getCheckbox(hash, false);
    Storage.local_setCheckbox(hash, toggle);
    this.setState({ changes: 1 });
    if (special > 0 && toggle) {
      for (let key in this.state.specials) {
        // uncheck the other option
        let entryhash = this.state.specials[key];
        if (entryhash != hash) {
          Storage.local_setCheckbox(entryhash, false);
        }
      }
    }
  }

  _drawCheck(enabled) {
    if (enabled) {
      return (
        <IconMoon
          style={{ position: "absolute", left: 15, top: -5 }}
          name={"cb-check"}
          color={"#090"}
          size={48}
        />
      );
    }
    return null;
  }

  _drawCheckbox(page, itemkey, wd) {
    // draw background square, with overlaid check mark.
    // get current value of the checkbox
    var enabled = Storage.local_getCheckbox(page.hash, false);
    var special = typeof page.special !== "undefined" ? page.special : 0;
    return (
      <TouchableOpacity
        onPress={() => {
          this.checkmark(page.hash, page.special, itemkey);
        }}
      >
        <View style={{ width: wd }}>
          <IconMoon
            style={{ position: "absolute", left: 15 }}
            name={"cb-box"}
            color={"#777"}
            size={38}
          />
          {this._drawCheck(enabled)}
        </View>
      </TouchableOpacity>
    );
  }

  // if the condition is true, draw the children, otherwise ignore
  _drawConditional(keyindex, page) {
    let key = page.key;
    let first = null;
    let parent_block = page[".parent"];
    for (let i = 0; i < parent_block.children.length; i++) {
      if (parent_block.children[i].type == "conditional") {
        first = parent_block.children[i].value;
        break;
      }
    }
    let current = Storage.local_getValue(key, first);
    if (page.value !== current) return null;

    return this.Children(page);
  }

  // https://stackoverflow.com/questions/728360/how-do-i-correctly-clone-a-javascript-object  (original handles date objects too)
  _clone(obj) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null == obj || "object" != typeof obj) return obj;

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (var i = 0, len = obj.length; i < len; i++) {
        copy[i] = this._clone(obj[i]);
      }
      return copy;
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = this._clone(obj[attr]);
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  // text2 is an Element with children.
  onRenderElement(text1, text2, onsameline) {
    let node;
    if (typeof text2.children === "undefined") {
      return "(unable to render object)";
    }

    let tempcopy = this._clone(text2); // work on a copy of the object.

    if (onsameline && text1 != "") {
      // if first child is text, add text1 to the child
      if (
        tempcopy.children.length > 0 &&
        tempcopy.children[0].type == "block"
      ) {
        node = tempcopy.children[0];
        node.text = text1 + " " + node.text;
      } else {
        node = {
          type: "block",
          size: 10, // to do: pick this up from somewhere
          text: text1,
        };
        tempcopy.children.unshift(node); // otherwise insert a new first child
      }
    }

    return this.Children(tempcopy);
  }

  onChangeDropdown(params) {
    let { id, expanded, group, parentgroup, parentindex } = params;
    let node = this.references[group];
    if (node !== null) {
      var position = node.changeDropDown(id, expanded);
      if (position !== false) {
        this.props.onScrollToPosition(position + marginTop);
      }
    }
    this.setState({ dropCount: this.state.dropCount + 1 });

    // since dropdown has changed, send a message to parent to resize (needed for Safari on mobile)
    if (parentgroup) {
      // Panel item within parent dropdown should be resized.
      let item = this.references[parentgroup].getItemByIndex(parentindex);
      if (item !== null) item.redraw();
    }
  }

  onAnimationEnd(params) {
    let { id, expanded, group, parentgroup, parentindex } = params;
    // just finished the collapse animation, now start with the expand
    var position = this.references[group].animationEnd(id, expanded);
    if (position !== false) {
      this.props.onScrollToPosition(position + marginTop);
      this.setState({ dropCount: this.state.dropCount + 1 });
    }
  }

  onLayoutLineItem = (e, key, group) => {
    let item = this.references[group].getItemById(key);
    if (item !== null) {
      item.savePosition({
        top: e.nativeEvent.layout.y,
        height: e.nativeEvent.layout.height,
      });
    }
  };

  onLayout2 = (e, page, panel) => {
    if (page.type == "lineitem" && page.image) {
      this.onLayoutIllustration(e, page.image, panel);
    }
  };

  onLayoutIllustration = (e, page, parentPanel = null) => {
    let wd = e.nativeEvent.layout.width;
    let resource = ProcedureData.getImageInfo(page.name);
    if (
      typeof resource.current_width === "undefined" ||
      resource.current_width != wd
    ) {
      ProcedureData.setDisplayWidth(page.name, wd);
      this.setState({ dropCount: this.state.dropCount + 1 });
      if (parentPanel) {
        parentPanel.refresh();
      }
    }
  };

  _drawIllustration(page) {
    let scale;
    let resource = ProcedureData.getImageInfo(page.name);
    if (resource === null) return null; // not found

    let width =
      typeof resource.current_width !== "undefined"
        ? resource.current_width
        : 0;
    if (!width) {
      return (
        <Image
          key={this.state.imageTicker}
          source={{
            uri: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=",
          }}
          style={{
            width: 1,
            height: 1,
          }}
        />
      );
    }
    if (page.scale) {
      if (typeof page.scale == "string") page.scale = parseFloat(page.scale);
      scale = page.scale / 100;
    } else {
      scale = width / resource.wd;
      if (scale > 1.05) scale = 1.05;
    }
    let imagedata = ProcedureData.getQueuedImage(this, resource);

    if (page.url) {
      return (
        <TouchableOpacity
          onPress={() => {
            this.link_click(page.url);
          }}
        >
          <Image
            key={this.state.imageTicker}
            source={imagedata}
            style={[
              Styles.image_clickable,
              {
                width: resource.wd * scale,
                height: resource.ht * scale,
              },
            ]}
          />
        </TouchableOpacity>
      );
    }

    return (
      <Image
        key={this.state.imageTicker}
        source={imagedata}
        style={[
          Styles.image,
          {
            width: resource.wd * scale,
            height: resource.ht * scale,
          },
        ]}
      />
    );
  }

  onPanelHeader = (params) => {
    let { page } = params;
    if (!page.image) return;

    return (
      <View style={[Styles.centeredContent, { marginTop: 15 }]}>
        {this._drawIllustration(page.image)}
      </View>
    );
  };

  assignReference(ref, key, group) {
    // key = ref.props.id; group = ref.props.page.group
    if (typeof this.references[group] === "undefined") {
      this.references[group] = new accordion(group);
    }
    if (ref === null) {
      // element no longer exists - remove our reference to it
      this.references[group].removeItem(key);
    } else {
      this.references[group].addItem(ref);
    }
  }

  _getParentDropdown(page) {
    let parent_block = page[".parent"];
    if (!parent_block) {
      return { group: -1, index: -1 }; // not found
    }
    if (parent_block.type == "lineitem") {
      let index =
        typeof parent_block.index !== "undefined" ? parent_block.index : 0;
      return { group: parent_block.group, index: index };
    }
    return this._getParentDropdown(parent_block);
  }

  /*	_getInitialState(key, group) {
			if (typeof this.references[group] === 'undefined') return false;
			
			let active = this.references[group].initialDropdown();
			return key == active;
		} */

  drawLineItem(page, key) {
    let parentgroup, parentindex, res;
    let body = this.DropDown(page);
    let parent_block = page[".parent"];
    if (page.depth <= 1) {
      // at top-level dropdown - it does not have a parent
      res = { group: -1 };
    } else {
      res = this._getParentDropdown(page);
    }
    if (res.group == -1) {
      parentgroup = parentindex = 0;
    } else {
      parentgroup = res.group;
      parentindex = res.index;
    }

    //		let expanded = this._getInitialState(key, page.group);
    let expanded = false;
    //  props.index is used by accordion.getItemByIndex;

    let checkbox = typeof page.checkbox === "undefined" ? false : page.checkbox;
    if (checkbox) {
      var wdCol1 = 60;
      var wdCol2 = this._width - wdCol1 - 50;
      return (
        <View
          style={[Styles.dropDownContainer, { flex: 1, flexDirection: "row" }]}
        >
          {this._drawCheckbox(page, key, wdCol1)}
          <Panel
            style={{ width: wdCol2 }}
            id={key}
            page={page}
            body={body}
            expanded={expanded}
            hilite={expanded}
            ref={(ref) => this.assignReference(ref, key, page.group)}
            parentgroup={parentgroup}
            parentindex={parentindex}
            index={typeof page.index !== "undefined" ? page.index : -1}
            onPanelHeader={(params) => {
              return this.onPanelHeader(params);
            }}
            onAnimationEnd={(params) => {
              this.onAnimationEnd(params);
            }}
            onChange={(params) => {
              this.onChangeDropdown(params);
            }}
            onRenderElement={(text1, text2, onsameline) => {
              return this.onRenderElement(text1, text2, onsameline);
            }}
            onLayout={(event, panel) => this.onLayout2(event, page, panel)}
          />
        </View>
      );
    } else {
      return (
        <View style={Styles.dropDownContainer}>
          <Panel
            id={key}
            page={page}
            body={body}
            expanded={expanded}
            hilite={expanded}
            ref={(ref) => this.assignReference(ref, key, page.group)}
            parentgroup={parentgroup}
            parentindex={parentindex}
            onPanelHeader={(params) => {
              return this.onPanelHeader(params);
            }}
            onAnimationEnd={(params) => {
              this.onAnimationEnd(params);
            }}
            onChange={(params) => {
              this.onChangeDropdown(params);
            }}
            onRenderElement={(text1, text2, onsameline) => {
              return this.onRenderElement(text1, text2, onsameline);
            }}
            onLayout={(event, panel) => this.onLayout2(event, page, panel)}
          />
        </View>
      );
    }
  }

  // the date has changed (the value has been saved) - redraw the page
  _selectDate(date) {
    this.onChange(null); // parameter is unused
  }

  _changeDate() {
    this.setState({ showdate: true, changes: 1 });
  }

  _selectPage(type, value, duration) {
    let page = PaiCalendar.getPage(type, value, duration);
    this.props.navigation.navigate("content", {
      page: page,
    });
  }

  _onLoad(component) {
    this._scroller = component;
  }

  _onProgress(position) {
    this._scroller.audioPositionChanged(position);
  }

  _getIframeHeight(page) {
    let wd = this._width;
    if (page.height) {
      if (typeof page.height == "string") {
        let found = page.height.match(/^(.*)\%/);
        if (found !== null) {
          found = parseFloat(found[1]);
          return (this._width * found) / 100;
        }
        return parseInt(page.height);
      }
      return page.height;
    }
    return this._width / 2;
  }

  onChangeText = (text) => {
    this.setState({ text });
    Storage.local_setValue("messageComment", text);
  };

  onPressSend = () => {
    DropDownHolder.alert(
      "success",
      "Thank You",
      "Your message was sent successfully",
      4000
    );

    // let content_text = ProcedureData.contentText();
    // let satisfied = this._getValue('satisfied');
    // let helpful = this._getValue('helpful');
    // if (this.state.text === '' &&
    // 	satisfied === '' &&
    // 	helpful === '') {
    // 	DropDownHolder.alert('error', 'Error', 'Please select a rating, or enter a comment', 4000);
    // 	return;
    // }
    // var payload = {
    // 	uuid: this.uuid,
    // 	source: content_text.splash.appname,
    // 	os: Platform.OS,
    // 	satisfied: satisfied,
    // 	helpful: helpful,
    // 	feedback: this.state.text,
    // }

    // var api_url = Storage.source(0);
    // fetch(api_url + '/feedback', {
    // 	method: 'POST',
    // 	headers: {
    // 		Accept: 'application/json',
    // 		'Content-Type': 'application/json',
    // 	},
    // 	body: JSON.stringify(payload),
    // }).then((responseJson) => {
    // 	DropDownHolder.alert('success', 'Thank You', 'Your feedback was sent successfully', 4000);
    // 	return 1;
    // }).catch((error) => {
    // 	DropDownHolder.alert('error', 'Error', 'Your feedback was not submitted. The error was: ' + error, 4000);
    // });
  };

  Block(page, key, imagestyle = null) {
    console.log("page ======>>>>> ", page);
    switch (page.type) {
      case "header":
      case "subheader":
      case "page":
        return (
          <View key={key} style={{ opacity: 1 }}>
            {this.Children(page)}
          </View>
        );

      case "lineitem":
        return (
          <View
            key={key}
            onLayout={(event) => this.onLayoutLineItem(event, key, page.group)}
          >
            {this.drawLineItem(page, key)}
          </View>
        );

      case "block": // just a block of text, no children
        return <View key={key}>{this._drawBlock(page)}</View>;

      case "link":
        return <View key={key}>{this._drawLink(page)}</View>;

      case "tab":
        return (
          <View key={key}>
            {this._drawTab(page)}
            {this._drawTabContent(page)}
          </View>
        );

      case "banner":
        return null; // handled first

      case "table":
        return <PaiTable key={key} page={page} />;

      case "illustration":
        if (imagestyle === null) {
          imagestyle = StyleSheet.flatten(Styles.centeredContent, {
            marginTop: 15,
          });
        }
        return (
          <View
            key={key}
            onLayout={(event) => this.onLayoutIllustration(event, page)}
            style={imagestyle}
          >
            {this._drawIllustration(page)}
          </View>
        );

      case "playback":
        this.state.text = Storage.local_getValue("messageComment", "");
        return (
          <View key={key}>
            <TextInput
              style={Styles.f_input}
              multiline={true}
              numberOfLines={10}
              onChangeText={this.onChangeText}
              value={this.state.text}
            />
            {/* <Button
							style={Styles.f_button}
							onPress={this.onPressSend}
							title="Send Message"
							color="#ff6633"
							accessibilityLabel="Send Message to Surgeon"
						/> */}
          </View>
          // <View key={key} style={[Styles.centeredContent, { marginTop: 30 }]}><Playback block={page} onprogress={(position) => this._onProgress(position)} /><PaiMarquee onload={(component) => this._onLoad(component)} /></View>
        );

      case "feedback":
        return <Feedbacks key={key} width={this._width} />;

      case "html":
        let html_attr = {
          width: this._width,
          height: this._getIframeHeight(page),
          borderColor: "#900",
          borderWidth: 1,
        };
        if (Platform.OS === "web") {
          return (
            <iframe
              key={key}
              src={page.url}
              style={html_attr}
              allowFullScreen
              frameBorder="0"
            ></iframe>
          );
        }
        if (this.state.appstate == "active") {
          return (
            <WebView
              key={key}
              style={html_attr}
              javaScriptEnabled={true}
              source={{ uri: page.url }}
              originWhitelist={["*"]}
            />
          );
        }
        return null;

      case "warning":
        let parent_block = page[".parent"];
        return (
          <View key={key}>
            <WarnOnBlur
              parent={parent_block}
              header={page.header}
              subheader={page.subheader}
            />
            <View>
              <Text style={Styles.checkboxnote}>
                <IconMoon name={page.iconname} size={page.iconsize} />{" "}
                {page.message}
              </Text>
            </View>
          </View>
        );

      case "radio":
        return <View key={key}>{this._drawRadio(page)}</View>;

      case "conditional": // like a tab, but only show the block if the variable matches
        return this._drawConditional(key, page);

      case "divider": // v1.9.22
        return (
          <View key={key}>
            <View style={Styles.divider} />
          </View>
        );

      // case 'faq':
      // 	return (
      // 		<View key={key}>{this._drawLink(page)}</View>
      // 	);

      // case 'about':
      // 	return (
      // 		<View key={key}>{this._drawLink(page)}</View>
      // 	);

      default:
        var res = Specials.generateCustomBlock(page, key);
        if (res !== null) return res;
        res = PaiCalendar.generateCustomBlock(
          page,
          {
            onDatePress: this._selectDate,
            onChangePress: this._changeDate,
            onHeaderPress: this._selectPage,
          },
          key,
          this.state
        );
        if (res !== null) return res;

        return (
          <View key={key}>
            <Text>Unknown block type {page.type}</Text>
            {this.Children(page)}
          </View>
        );
    }
  }

  // to do: check conditionals
  static _scanforbanner(page, activetab) {
    if (page.type == "banner") {
      return page.name;

      // skip this if isTab and not active,
    } else if (page.type == "tab" && page.hash != activetab) {
      return null;
    }
    var result = null;
    if (typeof page.children != "undefined") {
      for (let i = 0; i < page.children.length; i++) {
        var info = PaiPage._scanforbanner(page.children[i], activetab);
        if (info !== null) result = info;
      }
    }
    return result;
  }

  // does a page have navigation menu (without any dropdowns)?
  _hasLinks(page) {
    let counter_link = 0;
    let counter_dropdown = 0;
    if (typeof page.children != "undefined") {
      for (let i = 0; i < page.children.length; i++) {
        let info = this._childType(page.children[i]);
        if (info == "link") counter_link++;
        if (info == "lineitem") counter_dropdown++;
      }
    }
    return counter_link > 0 && !counter_dropdown;
  }

  _childType(page) {
    return page.type;
  }

  _menuObject() {
    let p = this.props.page;
    let menuPage;

    if (p.pagenum == "1000") {
      menuPage = {
        caption: "",
        children: p.children,
        hash: p.hash,
        icon: "",
        pagenum: p.pagenum,
        title: p.title,
        type: "subheader",
      };

      let children = [
        {
          text: "The specified topic could not be located in this surgical guide",
          type: "banner",
          size: 12,
        },
        {
          text: "How will I feel afterwards?",
          type: "link",
          target: "1001",
          size: 12,
          pn: 11,
        },
        {
          text: "When can I leave?",
          type: "link",
          target: "1002",
          size: 12,
          pn: 11,
        },
        {
          text: "Where do I go?",
          type: "link",
          target: "1003",
          size: 12,
          pn: 11,
        },
        {
          text: "Are there alternatives?",
          type: "link",
          target: "1004",
          size: 12,
          pn: 8,
        },
        {
          text: "Will I feel anything?",
          type: "link",
          target: "1005",
          size: 12,
          pn: 11,
        },
        {
          text: "Does this symptom matter?",
          type: "link",
          target: "1006",
          size: 12,
          pn: 22,
        },
        {
          text: "Can I give feedback?",
          type: "link",
          target: "1007",
          size: 12,
          pn: 23,
        },
        {
          text: "Parking?",
          type: "link",
          target: "1008",
          size: 12,
        },
        {
          text: "I need to cancel",
          type: "link",
          target: "1008",
          size: 12,
        },
      ];
      menuPage.children = children;
    } else menuPage = p;

    return menuPage;
  }

  render() {
    if (this.props.page.children) {
      let page = this._menuObject();

      let mx = this.state.windowWidth - 30;
      if (mx > 1024) mx = 1024;
      if (mx > 600 && this._hasLinks(page)) mx = 600;
      this._width = mx;
      let width = this.state.windowWidth;
      marginTop = 0;
      if (typeof this.state.background === "string") {
        let alternativename = this.state.background;
        alternativename += width < 440 ? ".0" : ".1";
        let resource = ProcedureData.getImageInfo(alternativename);

        if (resource === null) {
          alternativename = this.state.background;
          resource = ProcedureData.getImageInfo(alternativename);
        }

        if (width >= resource.wd || width > 800) width = 800;

        let { wd, ht } = resource;
        let imageheight = (width * ht) / wd;
        if (imageheight > this.state.windowHeight / 2)
          imageheight = this.state.windowHeight / 2;

        // marginTop += imageheight;
        // marginTop -= 20;
        marginTop = 10;
      }
      if (Platform.OS === "ios") {
        marginTop -= 5;
      } else if (Platform.OS === "web") {
      } else {
        marginTop = 10;
        //			marginTop += 15;
      }

      return (
        <View
          onLayout={this.onLayout}
          style={[Styles.pageContainer, { marginTop: marginTop, width: mx }]}
        >
          <Watch page={page.pagenum} singlepage={0} />
          <View style={{ padding: 0, opacity: 1 }}>{this.Block(page, -1)}</View>
          {page.pagenum < 3 && (
            <View style={Styles.checkboxContainer}>
              <CheckBox
                value={this.state.agree}
                onValueChange={this.onChangeCheckBok}
                style={Styles.checkbox}
              />
              <Text style={Styles.label} onPress={this.onChangeCheckBok}>
                I agree to the{" "}
                <Text style={Styles.agree} onPress={this.onPressAgree}>
                  terms & agreement
                </Text>
              </Text>
            </View>
          )}
        </View>
      );
    } else {
      return <></>;
    }
  }
}

export default withNavigation(PaiPage);
