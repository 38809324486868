
import React, { Component } from 'react';
import {Platform} from 'react-native';
import AsyncStorage from '@react-native-async-storage/async-storage';
import default_json from '../theme/master.json';
import Constants from 'expo-constants';
import Storage from '../components/Storage';

/*
	node_modules/expo/build/launch/AppLoading.js

	when app is published, it has an initial copy of master.json 
	
 load global settings:
   url of api
     -- available languages
			language translations
			
     -- available generic procedure guides
			abbreviation
			name
			isGeneric?		(pai- prefix)  only generic ones are listed
			status - missing, beta, release
			version (date)
			url of payload.zip for this procedure - json and images

	   -- version number of current global settings file

 version number of installed settings

 https://pusher.com/tutorials/offline-react-native-part-1


Android 5.0.1 or earlier fails with "TypeError: Network request failed"

* https://letsencrypt.org/certificates/
* From chrome, download and install this file: https://letsencrypt.org/certs/isrgrootx1.der

* videos will not play:
        https://pki.goog/repository/
 
*/

	async function storeItem(key, item) {
		try {
	/*
		AsyncStorage is a simple, unencrypted, asynchronous, persistent, key-value storage system that is global to the app.
		It should be used instead of LocalStorage.
	*/
			var jsonOfItem = await AsyncStorage.setItem(key, JSON.stringify(item));
			return jsonOfItem;
		} catch (error) {
			console.log(error.message);
		}
	}
	
	async function retrieveItem(key) {
		try {
			const retrievedItem =  await AsyncStorage.getItem(key);
			const item = JSON.parse(retrievedItem);
			return item;
		} catch (error) {
			console.log(error.message);
		}
		return false;
	}
	
	function _fetchMasterJson(resolve) {
		let version = encodeURIComponent(Constants.manifest.version); // VERSION 1.9.37
		var api_url = Storage.source(0);
		fetch(api_url + '/master.json?version=' + version + '&app=' + Platform.OS + '&_=' + Math.floor(Date.now() / 1000), {
			method: 'GET',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
		}).then(res => {
			// url = https://patientapps.net/api/master.json?version=1.9.37&app=web&_=1674277855
			// let x_info = res.headers.forEach(function(value, name){ 
//				console.log(name, value);
			// });
			return res.json();
		}).then((response) => {
			// get procedures
			storeItem('pai:master.json', response);
			global.master = response;
			resolve(1);
			return 1;
		}).catch((error) => {
			// offline or missing - use current copy (which may be the master if not already loaded)
			global.master = default_json;
			resolve(1);
			return 0;
		});
	}
	
	function clear() {
		AsyncStorage.removeItem('pai:master.json');
		delete global.master;
	}
	
    // 
    function getDefaultProcedure() {
        if (global.master && global.master.default) {
            return global.master.default;
        }
        return 'pai-lcl';
    }
    
	// master.json should be downloaded once per day
	function _startLoadingAppResourcesAsync() {
		return new Promise((resolve, reject) => {
			try {
				let contents;
				// download the cached copy
				retrieveItem('pai:master.json').then((data) => {
					let now = parseInt(Date.now() / 1000);
					if (data === null) {
						_fetchMasterJson(resolve);
					} else {
						global.master = data;
						
						// are we using an old version?
						if (typeof data.snapshot === 'undefined' || data.snapshot < now - 86400) {
							_fetchMasterJson(resolve);
						} else {
							resolve(1);
						}
						
					}
				}).catch((error) => {
					_fetchMasterJson(resolve);
				}); 
			}
			catch (e) {
//				throw e;
				reject(e);
			}
			finally {
			}
		});
	}
	
	function getProductInfo(product, allproducts = true) { // GET PRODUCT INFO
		if (global.master && global.master.procedures) {
			for(let i = 0; i < global.master.procedures.length; i++) {
				let node = global.master.procedures[i];
				let nocache = typeof node.nodecache !== 'undefined' ? node.nodecache : 0;
				if (node.abbr === product && (!nocache || allproducts)) {
					return node;
				}
			}
		}
		return null;
	}

	function _updateMasterList(item) {
		let node = getProductInfo(item.abbr);
		if (node !== null) {
			node.location = item.location;
			return;	// already in the list - no need to add it again
		}
		global.master.procedures.push(item);
		storeItem('pai:master.json', global.master);
	}
	
	// check the website to see if the product code is valid.
	function procedure_checkNew(host_id, productcode, debug = -1) {
		var api_url = Storage.source(host_id);
		
		return new Promise((resolve) => {
			let version = encodeURIComponent(Constants.manifest.version);
			let safepc = encodeURIComponent(productcode);
            let url = api_url + '/check-product?pc=' + safepc + '&version=' + version + '&app=' + Platform.OS + '&_=' + Math.floor(Date.now() / 1000);
            if (debug > -1) {
                console.log(debug, ' ', url);
            }
			fetch(url, {
				method: 'GET',
				headers: {
					Accept: 'application/json',
					'Content-Type': 'application/json',
				},
			}).then(res => {
				if (Math.floor(res.status / 100) != 2) {
					return {};
				} else {
					return res.json();
				}
			}).then((response) => {
				if (response.info) {
                    if (!response.info.group) {
                        _updateMasterList(response.info);
                    } 
					resolve(response.info);
				} else {
					resolve(null);
				}
			}).catch((error) => {
                console.log('>>>> ' + error + ' ' + url);
				resolve(null);
			});
		});
	}

	function procedure_validate(procedure, preview_mode = false, debug = -1) {
		if (procedure === false || procedure === null) {
			return new Promise((resolve) => {
				resolve(null);
			});
		} else if (preview_mode) {
			return new Promise((resolve) => {
				return procedure_checkNew(1, procedure, debug).then((value) => {
					let node = getProductInfo(procedure);				// online procedure value?
					resolve(node);
				}).catch((error) => {
					console.log('error', error);
					resolve(null);
				});
			});
		} else {
            return new Promise((resolve) => {
                let node = procedure !== null ? getProductInfo(procedure) : null;
                if (node === null) {
                    let location = Storage.local_getValue('location', 0);
                    return procedure_checkNew(location, procedure).then((value) => {
						let node = getProductInfo(procedure);				// online procedure value?
                        resolve(node);
                    }).catch((error) => {
                        console.log('error', error);
                        resolve(null);
                    });
                }
                resolve(node);
            });
        }
	}

	// returns a Promise
	function init() {
		global.master = false;
		global.weekTab = false;
		return _startLoadingAppResourcesAsync();
	}

export default {
  init,
  clear,
  getProductInfo,
  getDefaultProcedure,
  procedure_checkNew,
  procedure_validate,
};
