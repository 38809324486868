import React, { Component } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  ImageBackground,
  Dimensions,
  Image,
  Button,
  Platform,
  StyleSheet,
} from "react-native";
import * as Linking from "expo-linking";
import * as Font from "expo-font";

// import background_next from '../theme/homepage-next.png';
import ProcedureData from "../components/ProcedureData";
import PaiCalendar from "../components/PaiCalendar";
import Frame from "../components/Frame";
import Constants from "expo-constants";

import { createIconSetFromIcoMoon } from "@expo/vector-icons";
import CDropDown from "../components/CDropDown";
const Icon = createIconSetFromIcoMoon(
  require("../theme/icomoon.json"),
  "icomoon",
  "../theme/icomoon.ttf"
);

const PageStyle = StyleSheet.create({
  inner_content: {
    flexDirection: "column",
    alignSelf: "center",
    width: "95%",
  },
  header_top: {
    backgroundColor: "#0099cc",
    paddingTop: 40,
    paddingBottom: 20,
    borderTopRightRadius: 15,
    borderTopLeftRadius: 15,
  },
  header_logo: {
    width: 250,
    maxWidth: "100%",
    height: 40,
    margin: "auto",
  },
  header_bottom: {
    backgroundColor: "#0099cc",
    position: "relative",
    paddingTop: 50,
  },
  main_holder: {
    backgroundColor: "#fff",
    paddingTop: 30,
    paddingLeft: 20,
    paddingBottom: 30,
    paddingRight: 20,
    position: "relative",
    width: "100%",
    alignItems: "center",
  },
  bottom_overlay: {
    position: "absolute",
    bottom: 0,
    right: 0,
    left: -1,
  },
  img_fluid1: {
    width: 300, // 270
    maxWidth: "100%",
    height: 500, // auto
    margin: "auto",
    // verticalAlign: 'middle'
  },
  img_fluid2: {
    width: "100%",
    maxWidth: "100%",
    height: 76,
    margin: "auto",
    resizeMode: "stretch",
  },
  text_footer1: {
    fontSize: 14,
    fontWeight: "600",
    color: "black",
  },
  text_footer2: {
    fontSize: 17,
    fontWeight: "600",
    color: "#ff6633",
  },
  text_footer3: {
    fontSize: 15,
    color: "#7a7a7a",
    textAlign: "center",
    marginTop: 10,
  },
  button: {
    marginLeft: 30,
    marginRight: 30,
  },

  title_section: {
    //		borderColor: '#900',
    //		borderWidth: 1,
  },
  title_next: {
    marginTop: 16,
    marginBottom: 16,
    width: 170,
    height: 55,
    // backgroundImage: `url(${background_next})`,
    backgroundSize: "cover",
  },
  sec_header: {
    marginTop: 8,
    marginBottom: 8,
    color: "#ffff00",
    fontFamily: "exo2bold",
    textAlign: "center",
  },
  sec_title: {
    marginTop: 8,
    marginBottom: 8,
    color: "#ffffff",
    fontFamily: "exo2bold",
    textAlign: "center",
  },
  sec_tagline: {
    marginTop: 8,
    marginBottom: 8,
    color: "#ffff00",
    fontFamily: "exo2lite",
    fontStyle: "italic",
    textAlign: "center",
  },
  action: {
    fontSize: 26,
    color: "#414042",
    position: "absolute",
    fontFamily: "exo2lite",
    right: 8,
    top: 9,
  },
  link_text: {
    fontWeight: "bold",
    fontFamily: "exo2lite",
    color: "#000099",
  },
  footer_container: {
    marginTop: 27,
    flex: 6,
    flexDirection: "row",
    width: 360,
    maxWidth: "100%",
    alignItems: "center",
    backgroundColor: "rgba(255,255,255,0.2)",
    borderRadius: 5,
    paddingTop: 5,
    paddingBottom: 5,
  },
  addSubDealerBtn: {
    width: "80%",
    backgroundColor: "green",
    padding: 5,
    marginTop: 15,
  },
});

export default class TitlePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fontsLoaded: false,
      sizeChanged: 0,
    };
    // this.sec_header = StyleSheet.flatten(PageStyle.sec_header);
    // this.sec_title = StyleSheet.flatten(PageStyle.sec_title);
    // this.sec_tagline = StyleSheet.flatten(PageStyle.sec_tagline);
    // this.getFactors();
  }

  async LoadFonts() {
    await Font.loadAsync({
      icomoon: require("../theme/icomoon.ttf"),
      exo2bold: require("../theme/Exo2-SemiBold.otf"),
      exo2lite: require("../theme/Exo2-Light.otf"),
    });
    this.setState({
      fontsLoaded: true,
      sizeChanged: this.state.sizeChanged + 1,
    });
  }

  componentDidMount() {
    this.LoadFonts();
    PaiCalendar.load();
  }

  onpress = () => {
    if (this.props.expired) {
      let content_text = ProcedureData.contentText();
      console.log(
        "content_text.infolink =======>>>>> ",
        content_text,
        content_text.infolink
      );
      if (content_text && content_text.infolink) {
        Linking.openURL(content_text.infolink);
      }
    } else {
      this.props.dismiss();
    }
  };

  homepage = () => {
    Linking.openURL("https://patientapps.com");
  };

  website = () => {
    let content_text = ProcedureData.contentText();
    if (content_text && content_text.infolink) {
      Linking.openURL(content_text.infolink);
    } else {
      this.onpress();
      //            this.props.dismiss();
    }
  };

  prepareFooter(info) {
    let txt = null;
    if (info.gratis && !this.props.expired) {
      txt = (
        <Text onPress={this.website}>
          Gratis Period: <Text style={PageStyle.link_text}>{info.gratis}</Text>
          {`\n`}
        </Text>
      );
    }
    return (
      <View style={PageStyle.footer_container}>
        <View style={{ flex: 2 }}>
          <Icon
            name="pai-icon"
            size={80}
            color="#ffff00"
            style={{ alignSelf: "center" }}
          />
        </View>
        <View style={{ flex: 5 }}>
          <Text
            style={{ color: "#414042", fontFamily: "exo2bold", fontSize: 27 }}
          >
            PATIENT<Text style={{ color: "#ffff00" }}>APPS</Text>
            {`\n`}
          </Text>
          <Text
            style={{ color: "#414042", fontFamily: "exo2lite", fontSize: 16 }}
          >
            {txt}
            {info.text_version}
            {`\n`}
            {info.copyright},{" "}
            <Text onPress={this.homepage} style={PageStyle.link_text}>
              PatientApps, Inc.
            </Text>
            {`\n`}
          </Text>
        </View>
      </View>
    );
  }

  continueButton(info) {
    return (
      <ImageBackground
        // source={require('../theme/homepage-next.png')}
        imageStyle={{ resizeMode: "cover" }}
        style={{ width: 170, height: 55 }}
      >
        <Text style={PageStyle.action}>{info.text_continue}</Text>
      </ImageBackground>
    );
  }

  expiredText(info) {
    return (
      <Text style={[this.sec_title, { fontSize: 24 }]}>Trial has Expired</Text>
    );
  }

  getFactors() {
    let factor;
    var { width, height } = Dimensions.get("window");
    if (width < 512) {
      factor = 1;
    } else if (width < 2048) {
      // font-size: calc(1 + (0.5) * ((100vw - 512px) / (2048 - 512)));
      factor = 1 + 0.5 * ((width - 512) / 1536);
    } else {
      factor = 1.5;
    }
    this.fs_s = 18.6 * factor;
    this.fs_m = 21.3 * factor;
    this.fs_l = 26.6 * factor;
  }

  onLayout = (e) => {
    // this.getFactors();
    this.setState({ sizeChanged: this.state.sizeChanged + 1 });
  };

  render() {
    if (!this.state.fontsLoaded) {
      return null;
    }

    // let {width, height} = Dimensions.get('window');
    //		let land = width > height;
    let info = ProcedureData.productDetails();
    // let footer = this.prepareFooter(info);
    // let action = this.props.expired ? this.expiredText(info) : this.continueButton(info);

    return (
      <View>
        <View style={PageStyle.inner_content} onLayout={this.onLayout}>
          <View style={PageStyle.header_top}>
            <Image
              className="splash-image1"
              source={require("../img/header-logo.png")}
              style={PageStyle.header_logo}
            />
          </View>
          <View style={PageStyle.header_bottom}>
            <Image
              source={require("../img/others/main-img.png")}
              style={PageStyle.img_fluid1}
            />
          </View>
          <View style={PageStyle.bottom_overlay}>
            <Image
              source={require("../img/others/bottom-overlay.png")}
              style={PageStyle.img_fluid2}
            />
          </View>
        </View>
        <View style={PageStyle.main_holder}>
          <Text style={PageStyle.text_footer1}>{info.text_footer1}</Text>
          <Text style={PageStyle.text_footer2}>{info.text_footer2}</Text>
          <Text style={PageStyle.text_footer3}>{info.text_footer3}</Text>
        </View>
        <View style={PageStyle.button}>
          <Button onPress={this.onpress} color="#ff6633" title="Continue" />
        </View>
      </View>
    );
  }
}
