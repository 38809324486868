import React from "react";
import { Platform, View } from "react-native";

import { createStackNavigator } from 'react-navigation-stack';
import Icon from "react-native-vector-icons/FontAwesome5";
import CareTeamPage from "../pages/CareTeam";
import ContentPage from "../pages/Content";
import SettingsPage from "../pages/Settings";
import NavigationService from "../components/NavigationService";
import Styles, { Sharing, PerformSharing } from '../theme/Styles';
import ProcedureData from '../components/ProcedureData';
import Storage from '../components/Storage';
import Resource from '../components/Resource';
import { BottomTabBar, createBottomTabNavigator } from 'react-navigation-tabs';

const config = Platform.select({
  web: {
    headerMode: "screen",
  },
  default: {
  }
});

// Title color and Style are set in Styles.js:HeaderBar()
let config_content = Object.assign({
  lazy: false,
  headerTintColor: '#fff',
  defaultNavigationOptions: {
    cardStyle: { backgroundColor: 'transparent' },  // working
    headerTitleAlign: 'center',         // working
  }
}, config);
//config_content.initialRouteName = 'content';
config_content.initialRouteParams = { page: -999 };
let showCareTeam = true;
let currentPage = 0;

//--------------------------------------------------------------
export const ContentStack = createStackNavigator(
  {
    content: {
      screen: ContentPage,
      path: "content/:page",
      //	  params: {},
    }
  },
  config_content
);

ContentStack.navigationOptions = {
  tabBarLabel: " ", // Contents
  tabBarIcon: ({ focused }) => (
    <Icon name="smile" size={25} color={focused ? "#f37121" : "#999"} style={{ marginTop: 20 }} />
  ),
  tabBarOnPress: ({ navigation, defaultHandler }: any) => {
    // var pagenum = ProcedureData.getTocPage();
    if (global.current_page != 1)
      navigation.navigate("content", { page: 23 });
  },
  tabBarOnLongPress: ({ navigation, defaultHandler }: any) => {
    // var pagenum = ProcedureData.getTocPage();
    if (global.current_page != 1)
      navigation.navigate("content", { page: 23 });
  }
};

/* ContentStack.defaultNavigationOptions = {         // does not work
    headerTintColor: '#FFFFFF',
    headerTitleStyle: {
        fontWeight: 'bold',
    },
}; */


ContentStack.path = "";

// https://reactnavigation.org/docs/en/custom-routers.html
const prevGetPathAndParamsForState = ContentStack.router.getPathAndParamsForState;
ContentStack.router.getPathAndParamsForState = (state) => {
  let res = prevGetPathAndParamsForState(state);
  if (res.path == 'content/-999') {
    res.path = 'content/' + NavigationService.getCurrentPage();
  }
  return res;
}

//--------------------------------------------------------------

const CareTeamStack = createStackNavigator(
  {
    yourteam: {
      screen: CareTeamPage,
      path: "yourteam"
    }
  },
  config
);

CareTeamStack.navigationOptions = {
  tabBarVisible: true,
  tabBarLabel: " ", // Your Team
  // tabBarOptions: {
  //   showIcon: true,
  //   activeTintColor: '#e91e63',
  //   style: {
  //     backgroundColor: '#fff',
  //     borderTopRightRadius: 15,
  //     borderTopLeftRadius: 15,
  //     borderBottomRightRadius: 15,
  //     borderBottomLeftRadius: 15,
  //   },
  // },
  // tabBarIcon: ({ tintColor }) => {
  //   return (<Image
  //     style={{ width: 50, height: 50 }}
  //     source={require('../img/icons/menu-icon1.png')} />);
  // },

  tabBarIcon: ({ focused }) => (
    <Icon name="hospital-user" size={25} color={focused ? "#f37121" : "#999"} style={{ marginTop: 20 }} />
  ),
  tabBarOnPress: ({ navigation, defaultHandler }: any) => {
    if (global.current_page != 1)
      navigation.navigate("yourteam");
  },
  tabBarOnLongPress: ({ navigation, defaultHandler }: any) => {
    if (global.current_page != 1)
      navigation.navigate("yourteam");
  }
};

CareTeamStack.path = "";

//--------------------------------------------------------------

// const SettingsStack = createStackNavigator(
//   {
//     settings: {
//       screen: SettingsPage,
//       path: 'settings'
//     }
//   },
//   config
// );

// SettingsStack.navigationOptions = {
//   tabBarVisible: true,
//   tabBarLabel: " ", // Settings
//   tabBarIcon: ({ focused }) => (
//     <Icon name="share-alt" size={25} color={focused ? "#f37121" : "#999"} style={{ marginTop: 20 }} />
//   )
// };

// SettingsStack.path = "";

//--------------------------------------------------------------

class TabBar extends React.Component {
  /*	constructor (props) {
      super(props);
          this.state = {
              counter: 0,
          };
      } */


  render() {
    return (
      <BottomTabBar
        {...this.props}
        getButtonComponent={this.getButtonComponent}
      />
    )
  }

  getButtonComponent({ route }) {
    if (route.key === 'CareTeamStack' && !showCareTeam) {
      return () => <View /> // a view that doesn't render its children
    } else {
      return null // use the default nav button component
    }
  }
}

// https://reactnavigation.org/docs/en/bottom-tab-navigator.html

const tabNavigator = createBottomTabNavigator(
  {
    ContentStack,
    CareTeamStack,
    NavLeft: {
      path: "",
      screen: () => { return null }, //ContentStack,
      navigationOptions: {
        tabBarVisible: true,
        tabBarLabel: " ", // Previous
        tabBarIcon: ({ focused }) => (
          <Icon
            name="arrow-circle-left"
            size={29}
            color={focused ? "#f37121" : "#999"}
            style={{ marginTop: 20 }}
          />
        ),
        tabBarOnPress: ({ navigation, defaultHandler }: any) => {
          if (global.current_page != 1) {
            var pagenum = ProcedureData.getNextPage(-1);
            navigation.navigate("content", { page: pagenum });
          }
        },
        tabBarOnLongPress: ({ navigation, defaultHandler }: any) => {
          if (global.current_page != 1) {
            var pagenum = ProcedureData.getNextPage(-1);
            navigation.navigate("content", { page: pagenum });
          }
        }
      }
    },
    NavRight: {
      path: "",
      screen: () => { return null }, //ContentStack,
      //      screen: ContentStack,
      navigationOptions: {
        tabBarVisible: true,
        tabBarLabel: " ", // Next
        tabBarIcon: ({ focused }) => (
          <Icon
            name="arrow-circle-right"
            size={29}
            color={focused ? "#f37121" : "#999"}
            style={{ marginTop: 20 }}
          />
        ),
        tabBarOnPress: ({ navigation, defaultHandler }: any) => {
          if (global.current_page != 1) {
            var pagenum = ProcedureData.getNextPage(1);
            navigation.navigate("content", { page: pagenum });
          }
        },
        tabBarOnLongPress: ({ navigation, defaultHandler }: any) => {
          if (global.current_page != 1) {
            var pagenum = ProcedureData.getNextPage(1);
            navigation.navigate("content", { page: pagenum });
          }
        }
      }
    },
    // SettingsStack,
    ShareStack: {
      path: "",
      screen: () => { return null },
      navigationOptions: {
        tabBarVisible: true,
        tabBarLabel: " ", // Previous
        tabBarIcon: ({ focused }) => (
          <Icon
            name="share-alt"
            size={25}
            color={focused ? "#f37121" : "#999"}
            style={{ marginTop: 20 }}
          />
        ),
        tabBarOnPress: ({ navigation, defaultHandler }: any) => {
          if (global.current_page != 1) {
            let content_text = ProcedureData.contentText();
            let procedurecode = Storage.local_getValue('procedure', null);
            let res = PerformSharing(content_text, procedurecode);
            if (res !== null) {
              Resource.javascriptEvent('share', res);
            }
          }
        }
      }
    },
  },
  {
    lazy: false,
    resetOnBlur: true,
    tabBarComponent: TabBar,
    tabBarOptions: {
      //      showLabel: false,
      inactiveBackgroundColor: "#d0e5fa",
      activeBackgroundColor: "#d0e5fa",
      inactiveTintColor: "#eee",
      activeTintColor: "#fff"
    },
    cardStyle: {
      //            shadowColor: 'transparent',
      backgroundColor: "transparent"
    }
  }
);

tabNavigator.showCareTeam = (value) => {
  showCareTeam = value;
}

tabNavigator.path = "";

const prevGetStateForAction = tabNavigator.router.getStateForAction;

tabNavigator.router.getStateForAction = (action, state) => {
  let result = { value: true };
  // this is when changing pages, not for initial page load
  Storage.trigger('pageBlur', { action, state, result });
  if (result.value !== true) return result.value;
  return prevGetStateForAction(action, state);
};

const tab_prevGetPathAndParamsForState = tabNavigator.router.getPathAndParamsForState;
tabNavigator.router.getPathAndParamsForState = (state) => {
  let res = tab_prevGetPathAndParamsForState(state);
  if (res.path != 'content') {
    delete res.params.page;		// page is only used for Content
  }
  return res;
}


export default tabNavigator;
