import React, { Component } from "react";
import { TouchableOpacity, View, Text, Image, FlatList } from "react-native";
import ProcedureData from "../ProcedureData";
import FakeHtmls from "../FakeHtml";
import { createIconSetFromIcoMoon, AntDesign } from "@expo/vector-icons";

const expoAssetId = require("../../theme/icomoon.ttf");
import icoMoonConfig from "../../theme/icomoon.json";

const IconMoon = createIconSetFromIcoMoon(
  icoMoonConfig,
  "icomoon",
  expoAssetId
);

export default class CDropDown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedValue: this.props.contentdata[0] || {},
      openDropdown: false,
      defaultItem: {},
      data: this.props.contentdata,
      openDescription: false,
      defaultItem: {},
      subData: [],
    };
  }

  handleCollapsible = (item) => {
    const { openDescription, defaultItem } = this.state;
    if (openDescription) {
      if (item === defaultItem) {
        this.setState({
          openDescription: false,
        });
      } else {
        this.setState({
          openDescription: true,
        });
      }
    } else {
      this.setState({
        openDescription: true,
      });
    }
  };

  renderData = ({ item, index }) => {
    const { openDescription, defaultItem, subData } = this.state;
    const { onItemClick } = this.props;
    let icon = ProcedureData.getTargetIcon(item.target);
    if (!icon) icon = "doctor";
    return (
      <View
        style={{
          flexDirection: "column",
          flex: 1,
        }}
      >
        <View
          key={index}
          style={{
            backgroundColor: "#fff",
            marginHorizontal: 10,
            marginTop: 10,
            marginBottom:
              openDescription && item.text === defaultItem.text && subData
                ? 0
                : 10,
            padding: 10,
            borderTopEndRadius: 30,
            borderTopStartRadius: 30,
            borderBottomStartRadius:
              openDescription && item.text === defaultItem.text && subData
                ? 0
                : 30,
            borderBottomEndRadius:
              openDescription && item.text === defaultItem.text && subData
                ? 0
                : 30,
          }}
        >
          <View
            style={{
              flex: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <View
                style={{
                  backgroundColor: "orange",
                  borderRadius: 100,
                  height: 50,
                  width: 50,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconMoon
                  name={icon}
                  color={"#fff"}
                  size={30}
                  style={{
                    textAlign: "center",
                  }}
                />
              </View>
              <Text
                style={{
                  color:
                    openDescription && item.text === defaultItem.text && subData
                      ? "#0099cc"
                      : "#000",
                  marginLeft: 10,
                  fontSize: 16,
                  fontWeight: "600",
                }}
              >
                {item?.text}
              </Text>
            </View>
            <TouchableOpacity
              activeOpacity={0.5}
              onPress={() => {
                this.setState({
                  defaultItem: item,
                });
                this.handleCollapsible(item);
                const pg = ProcedureData.getPageFromHash(item.target);
                this.setState({
                  subData: ProcedureData.getPageData(pg + 1).children[2],
                });
              }}
              style={{
                borderWidth: 1,
                borderColor: "#000",
                borderRadius: 100,
                marginRight: 10,
                height: 30,
                width: 30,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <AntDesign
                name={
                  openDescription && item.text === defaultItem.text && subData
                    ? "up"
                    : "down"
                }
                size={20}
                color="black"
              />
            </TouchableOpacity>
          </View>
        </View>
        {openDescription && item.text === defaultItem.text && subData ? (
          <View
            style={{
              backgroundColor: "white",
              flexDirection: "column",
              flex: 1,
              marginHorizontal: 10,
              padding: 10,
            }}
          >
            <Text>{FakeHtmls.formattedText(subData?.text, 8)}</Text>
          </View>
        ) : null}
      </View>
    );
  };

  render() {
    const { data } = this.state;

    return (
      <View
        style={{
          marginTop: 10,
        }}
      >
        <FlatList
          data={data.filter((v) => v.type === "link")}
          renderItem={this.renderData}
        />
      </View>
    );
  }
}
