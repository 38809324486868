import React, { Component } from 'react';
import {
	Text,
	View,
	ScrollView,
	TouchableOpacity,
	Dimensions,
	Image
} from 'react-native';

import Styles, { HeaderBar } from '../theme/Styles';
import ProcedureData from '../components/ProcedureData';
import Icon from 'react-native-vector-icons/FontAwesome5';
import StreetAddress from '../components/StreetAddress';
import ItemList from '../components/ItemList';
import { withNavigation, DrawerActions } from 'react-navigation';
import FakeHtmls from '../components/FakeHtml';
import Storage from '../components/Storage';
import Watch from '../components/Watch';

// https://stackoverflow.com/questions/49951885/which-is-the-best-approach-to-insert-an-vector-svg-graph-into-a-react-native-a/49952050#49952050
// https://icomoon.io/app/#/select/font
// https://github.com/GeekyAnts/NativeBase/issues/1385


// These are fontawesome5 icons
class CareTeamPage extends Component {

	static navigationOptions = ({ navigation }) => {
		return HeaderBar(navigation, { title: 'Your Care Team' });
	}

	constructor(props) {
		let { width } = Dimensions.get('window');
		super(props);
		this.state = {
			//			isExpanded: true,
			//			isAnimated: false,
			windowWidth: width,
		};
		this.content_text = ProcedureData.contentText();
		this.asCareTeam = this.asCareTeam.bind(this);
		this.generateList = this.generateList.bind(this);
		this._isMounted = false;
		//        this.firstTime = true;
	}

	_onPageLoad(params) {
		let { action, state, result } = params;
		if (action.type == 'Navigation/NAVIGATE' && action.routeName == 'CareTeamStack') {
			Watch.registerEvent(-101, 'view');
			//            this.firstTime = false;
		}
	}

	/*    componentWillMount() {
			this.navFocusListener = this.props.navigation.addListener('didFocus', event => {
				if (this.firstTime) {
					Watch.registerEvent(-101, 'view');
					this.firstTime = false;
				}
			});
		} */

	componentDidMount() {
		Storage.on('pageBlur', this._onPageLoad);

		this._isMounted = true;
		if (this._isMounted) {
			Storage.off('pageBlur', this._onPageLoad);
			//this.navFocusListener.remove();
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	generateList(list, icon) {
		if (typeof list === 'undefined') return null;
		if (typeof icon === 'undefined') icon = 'check-circle';
		if (typeof list !== 'object') {
			list = [list];
		}
		return list.map(function (value, key) {
			return (
				<View key={key} style={[Styles.care_row, { paddingTop: 4 }]}>
					<View style={Styles.icon_small_container}>
						<Icon name={icon} style={Styles.icon_small} size={16} />
					</View>
					<Text> {value} </Text>
				</View>
			);
		});
	}

	// Display the old-style Your Team page
	asCareTeam(width) {
		var inner_width = width - 72;
		if (typeof this.content_text.care_team == 'undefined') return null;

		return this.content_text.care_team.contacts.map((value, i) => {
			let target = 'mailto:' + value.email;
			return (
				<View key={i} style={[Styles.care_container, { width: width }]}>
					<Watch page={-101} />
					<View style={Styles.care_icon}>
						<Icon name={value.icon} size={40} color="#fff" />
					</View>
					<View style={[Styles.care_items, { width: inner_width }]}>
						{value.title && <Text style={Styles.care_header}>{value.title}</Text>}
						{this.generateList(value.list, value.listicon)}
						{value.body && <View style={Styles.care_row}><Text>{value.body}</Text></View>}
						{value.email && <ItemList type={'email'} target={target} value={value.email} />}
						{value.phone && <ItemList type={'phone'} target={value.phone} value={value.phone} />}
						{value.oncall && <ItemList type={'oncall'} target={value.phone} value={value.phone} />}
						{value.address && <StreetAddress lines={value.address} />}
					</View>
				</View>
			);
		});
	}

	generateItem(value, key) {
		let target;
		switch (value.type) {
			case 'title':
				return (
					<Text key={key} style={Styles.care_header}>{value.text}</Text>
				);
			case 'list':
				if (typeof value.icon === 'undefined') value.icon = 'check-circle';
				return (
					<View key={key} style={[Styles.care_row, { paddingTop: 4 }]}>
						<View style={Styles.icon_small_container}>
							<Icon name={value.icon} style={Styles.icon_small} size={16} />
						</View>
						<Text> {value.text} </Text>
					</View>
				);
			case 'address':
				return (
					<StreetAddress key={key} lines={value.text} />
				)
			case 'phone':
				target = 'tel:' + value.text;
				return (
					<ItemList key={key} type={'phone'} target={target} value={value.text} extra={value.extra} />
				);
			case 'oncall':
				target = 'tel:' + value.text;
				return (
					<ItemList key={key} type={'oncall'} target={target} value={value.text} extra={value.extra} />
				);
			case 'blank':
				return (
					<ItemList key={key} type={'blank'} value={value.text} />
				);
			case 'email':
				target = 'mailto:' + value.text;
				return (
					<ItemList key={key} type={'email'} target={target} value={value.text} extra={value.extra} />
				);
			case 'http':
				target = (value.target) ? value.target : 'http://' + value.text;
				return (
					<ItemList key={key} type={'web'} target={target} value={value.text} extra={value.extra} />
				);
			case 'https':
				target = (value.target) ? value.target : 'https://' + value.text;
				return (
					<ItemList key={key} type={'web'} target={target} value={value.text} extra={value.extra} />
				);
			case 'map':
				return (
					<ItemList key={key} type={'map'} target={value.target} value={value.text} extra={value.extra} />
				);
			case 'text':
				if (typeof value.target !== 'undefined') {
					return (
						<ItemList key={key} type={'link'} target={value.target} value={value.text} extra={value.extra} />
					);
				}
				// fakehtml
				let fmttext = FakeHtmls.formattedText(value.text, 8);
				return (
					<View key={key} style={[Styles.care_row, { paddingTop: 4 }]}>{fmttext}</View>
				);
			default:
				console.log(value);
		}
	}

	asCareTeamNew(width) {
		let children;
		var inner_width = width - 100;

		let output = this.content_text.careteam.map((value, key) => {
			if (value.type === 'container') {
				children = value.children.map((value, key) => {
					return this.generateItem(value, key);
				});

				return (
					<View key={key}>
						{
							key == 1 &&
							<View key={'key'} style={{marginTop: 10, marginBottom: 10}}>
								<Text style={{fontSize: 15, color: '#1791c5', fontWeight: '700', textAlign: 'center'}}>Physician Offices and Clinics
								</Text>
							</View>
						}
						<View key={key} style={[Styles.care_container, { width: width }]}>
							<View style={Styles.care_icon}>
								<Icon name={value.icon} size={40} color="#fff" />
							</View>
							<View style={[Styles.care_items, { width: inner_width }]}>
								{children}
							</View>
						</View>
					</View>
				);
			} else {
				return this.generateItem(value, key);
			}
		});
		return (
			<ScrollView style={Styles.page_container} onLayout={this.onLayout}>
				<View style={[Styles.page_content, { width: width }]}>
					{output}
				</View>
			</ScrollView>
		);
	}

	onLayout = e => {
		var { width, height } = Dimensions.get('window');
		this.setState({
			width: e.nativeEvent.layout.width,
			height: e.nativeEvent.layout.height,
			windowWidth: width,
			windowHeight: height
		});
	};

	render() {
		let width = this.state.windowWidth - 20;
		if (width > 350) width = 350;
		if (typeof this.content_text.careteam !== 'undefined') {
			return (
				<View style={{backgroundColor: '#d0e5fa', height: '100%'}}>
					<Image source={require('../img/others/top-overlay.png')} style={Styles.top_round} />
					{this.asCareTeamNew(width)}
				</View>
			)
		}
		let pagedef = this.content_text.care_team;
		if (typeof pagedef === 'undefined') {
			pagedef = {
				body: 'Unable to display the "Your Care Team" page'
			};
		}
		// return (
		// 	<ScrollView style={Styles.page_container} onLayout={this.onLayout} >
		// 		<View style={[Styles.page_content, { width: width }]}>
		// 			{pagedef.body && <Text>{pagedef.body}</Text>}
		// 			{this.asCareTeam(width)}
		// 			{pagedef.footer && <Text>{pagedef.footer}</Text>}
		// 		</View>
		// 	</ScrollView>
		// );
	}
}

export default withNavigation(CareTeamPage);

