// process some html entities
import React, { Component } from 'react';

import { Platform, Text, View } from 'react-native';
import HyperLink from '../components/HyperLink';
import ProcedureData from '../components/ProcedureData';
import Styles from '../theme/Styles';

import FontAwesome5Icon from 'react-native-vector-icons/FontAwesome5';
import { createIconSetFromIcoMoon } from '@expo/vector-icons';
import icoMoonConfig from '../theme/icomoon.json';
const expoAssetId = require("../theme/icomoon.ttf");
const IconMoon = createIconSetFromIcoMoon(icoMoonConfig, 'icomoon', expoAssetId);

let fontSize;

function splitByTags(text) {
	var result = [];
	var last = 0;
	var inTag = false;
	for (let i = 0; i < text.length; i++) {
		let ch = text.charAt(i);
		if (ch === '<') {
			let subtext = text.substring(last, i);
			if (subtext !== '') result.push(subtext);
			inTag = true;
			last = i;
		} else if (ch === '>') {
			result.push(text.substring(last, i + 1));	// html token
			last = i + 1;
			inTag = false;
		}
	}
	if (!inTag) {
		if (last < text.length) result.push(text.substring(last));
	}
	return result;
}

function br(i) {
	return (
		<Text key={i}>{`\n`}</Text>
	)
}

function bulletAsText(depth) {
	var bullet;
	switch (depth) {
		case 0:
			bullet = '•';
			break;
		case 1:
			bullet = '◦';
			break;
		case 2:
			bullet = '▪';
			break;
		case 3:
			bullet = '▫';
			break;
		default:
			bullet = '*';
	}
	for (let i = 0; i < depth; i++) {
		bullet = '   ' + bullet;
	}
	return bullet;
}

// this is a <Text> structure - it cannot contain <View>
function _generateList(jsx, phrases, depth) {
	// find first li
	var value, ok, i;
	var bullet = bulletAsText(depth);

	while (phrases.length) {
		value = phrases.shift();
		if (value === '</ul>') break;
		if (value !== '<li>') {
			continue;
		}
		if (_hasParagraph(phrases)) {
			i = jsx.length;
			jsx.push(br(i));
		}
		var li = [];
		var inquote = 0;
		do {
			ok = false;
			if (!phrases.length) break;
			value = phrases[0];
			if (value === '<ul>') {
				inquote++;
			} else if (value === '</ul>') {
				inquote--;
			}
			if ((value !== '<li>' && value !== '</li>') || inquote > 0) {
				value = phrases.shift();
				li.push(value);
				ok = true;
			}
		} while (ok);
		if (li.length) {
			i = jsx.length;
			var res = (
				<Text key={i}>{bullet} {_processPhrases(li, depth + 1)}</Text>
			);
			jsx.push(res);
			i = jsx.length;
			jsx.push(br(i));
		}
		if (phrases.length && value === '</li>') {
			value = phrases.shift();
		}
	}
}

function _hasParagraph(phrases) {
	var pos = 0;
	while (pos < phrases.length) {
		var value = phrases[pos];
		if (value.charAt(0) !== '<') {
			if (value.trim() === '') {
				pos++;
				continue;
			}
			return false;
		} else if (value === '<p>') {
			pos++;
			phrases.splice(0, pos);
			return true;
		} else {
			return false;
		}
	}
	return false;
}

function _generateIcon(htmlfragment, key, styles) {
	let matches = htmlfragment.match(/name="(.*?)"/);
	let name = matches[1];
	let className = 'iconmoon';
	if (!styles.hasOwnProperty('color')) {
		styles.color = '#000000';
	}
	matches = htmlfragment.match(/class="(.*?)"/)
	if (matches !== null) className = matches[1].toLowerCase();
	if (className === 'fontawesome5') {
		return (
			<FontAwesome5Icon key={key} name={name} size={fontSize} color={styles.color} />
		);
	}
	return (
		<IconMoon key={key} name={name} size={fontSize} color={styles.color} />
	);

}

function _effectiveStyle(styleStack) {
	let style = { fontStyle: 'normal', fontWeight: 'normal' };
	for (let i = 0; i < styleStack.length; i++) {
		let item = styleStack[i];
		if (item.hasOwnProperty('fontStyle')) {
			style.fontStyle = item.fontStyle;
		}
		if (item.hasOwnProperty('fontWeight')) {
			style.fontWeight = item.fontWeight;
		}
		if (item.hasOwnProperty('color')) {
			style.color = item.color;
		}
		if (item.hasOwnProperty('fontSize')) {
			style.fontSize = _mapSize(parseInt(item.fontSize));
		}
		if (item.tag && item.tag == 'sup') {		// superscript
			style.fontSize = Math.floor(fontSize * 2 / 3);
			style.textAlignVertical = 'top';		// Android & Web only
		}
	}
	return style;
}

function _popStyle(styleStack, tag) {
	for (let i = styleStack.length - 1; i >= 0; i--) {
		if (styleStack[i].tag == tag) {
			styleStack.splice(i, 1);
			return;
		}
	}
}

function _getSpanAttributes(value) {
	let node = { tag: 'span' };
	let matches = value.match(/style="(.*?)"/);
	if (matches !== null) {
		let style = matches[1];
		let declarations = style.split(';');
		for (let i = 0; i < declarations.length; i++) {
			let line = declarations[i].trim();
			if (line != '') {
				line = line.split(':');
				let key = line[0].trim();
				let value = line[1].trim();
				node[key] = value;
			}
		}
	}
	return node;
}


// this is a <Text> structure - it cannot contain <View>
function _processPhrases(phrases, depth, fontcolor = null) {
	let value, res, i;
	let styles;
	let jsx = [];
	let header = '';
	let inLink = false;
	let styleStack = [];
	if (fontcolor !== null) styleStack.push({ tag: 'body', color: fontcolor });	// default color
	let spaced = true;		// any leading space should be removed
	let atstart = true;
	let paraspacing = true;	// include a space between paragraphs

	while (phrases.length) {
		value = phrases.shift();
		i = jsx.length;
		if (value.charAt(0) !== '<') {
			switch (header) {
				case 'h1':
					styles = Styles.h1;
					break;
				case 'h4':
					styles = Styles.h4;
					break;
				default:
					styles = _effectiveStyle(styleStack);
					break;
			}
			if (spaced) {
				if (value.charAt(0) == ' ') {
					value = value.substring(1);
				}
			}
			// spaced = trailing space present?
			let k = value.length - 1;
			spaced = (k >= 0) && value.charAt(k) == ' ';

			value = value.replace(/&nbsp;/g, ' ')
				.replace(/&lt;/g, '<')
				.replace(/&gt;/g, '>')
				.replace(/&amp;/g, '&');
			value = ProcedureData.macro(value, true);
			// replace macros here(visible)
			if (inLink !== false) {
				inLink = inLink.trim();
				res = (
					<HyperLink key={i} style={styles} title={value} url={inLink} />
				);
			} else {
				res = (
					<Text key={i} style={styles}>{value}</Text>
				);
			}
			jsx.push(res);
			atstart = false;
		} else if (value === '<br>') {		// v1.9.22
			jsx.push(br(i));
			spaced = true;
			atstart = true;
		} else if (value === '<p>') {
			if (paraspacing) {
				jsx.push(br(i));
				i = jsx.length;
				jsx.push(br(i));
			}
			paraspacing = true;
			spaced = true;
			atstart = true;
		} else if (value === '</a>') {
			inLink = false;
		} else if (value.substring(0, 3) === '<a ') {
			let matches = value.match(/href="(.*?)"/);
			if (matches !== null) {
				inLink = ProcedureData.macro(matches[1], false);
				continue;
			}
			matches = value.match(/link="(.*?)"/);
			if (matches !== null) {
				inLink = '*' + matches[1];
			}
		} else if (value.substring(0, 5) === '<span') {
			styleStack.push(_getSpanAttributes(value));

		} else if (value.substring(0, 6) === '<icon ') {
			styles = _effectiveStyle(styleStack);
			res = _generateIcon(value, i, styles);
			jsx.push(res);
			spaced = false;

		} else if (value === '<ul>') {
			if (!atstart) jsx.push(br(i));
			atstart = true;

			_generateList(jsx, phrases, depth);
			paraspacing = false;
			spaced = true;
			atstart = true;

		} else if (value === '<li>') {		// li without ul
			if (!atstart) jsx.push(br(i));
			atstart = true;
			value = bulletAsText(depth) + '  ';
			spaced = true;
			// maybe color and fontsize styles here
			res = (
				<Text key={i}>{value}</Text>
			);
			jsx.push(res);
		} else if (value === '</li>') {

		} else {		// update attributes
			switch (value) {
				case '<i>':
					styleStack.push({ tag: 'i', fontStyle: 'italic' });
					break;
				case '</i>':
					_popStyle(styleStack, 'i');
					break;
				case '<b>':
					styleStack.push({ tag: 'b', fontWeight: 'bold' });
					break;
				case '</b>':
					_popStyle(styleStack, 'b');
					break;
				case '<sup>':
					styleStack.push({ tag: 'sup' });
					break;
				case '</sup>':
					_popStyle(styleStack, 'sup');
					break;
				case '<h1>':
				case '<h2>':
				case '<h3>':
				case '<h4>':
				case '<h5>':
				case '<h6>':
					header = value.substring(1, 3);
					if (!atstart) {
						jsx.push(br(i));
						if (paraspacing) {
							i = jsx.length;
							jsx.push(br(i));
						}
					}
					spaced = true;
					atstart = true;
					break;
				case '</h1>':
				case '</h2>':
				case '</h3>':
				case '</h4>':
				case '</h5>':
				case '</h6>':
					header = '';
					jsx.push(br(i));
					paraspacing = false;
					spaced = true;
					atstart = true;
					break;
				case '</span>':
					_popStyle(styleStack, 'span');
					break;
				default:
					res = (
						<Text key={i} style={styles}>{value}</Text>
					);
					jsx.push(res);
			}
		}
	}
	return jsx;

}

function assembleStyledText(text, fs, fc = null) {
	if (text === null) return null;
	fontSize = fs;
	var phrases = splitByTags(text.trim());
	return _processPhrases(phrases, 0, fc);
}

function _mapSize(size) {
	switch (size) {
		case 8:
			return 14;
		case 10:
			return 16;
		case 12:
			return 17;
		case 16:
			return 23;
		default:
			return size * 2;
	}
}

function formattedText(text, size) {
	// Everything is one size
	let fs = _mapSize(size);
	let fc = '#222';
	let ta = 'left';
	if (size == 16) {
		fc = '#0099cc';
		ta = 'center';
	}
	return (
		<Text selectable={true} style={{ fontSize: fs, textAlign: ta }}>{assembleStyledText(text, fs, fc)}</Text>
	);
}

export default {
	formattedText,
	assembleStyledText,
}	