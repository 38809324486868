import React, { Component } from 'react';
import {
  View,
  ImageBackground,
  Platform,
  Dimensions,
} from 'react-native';
import Styles from '../theme/Styles';

export default class Frame extends Component {

	onLayout = e => {
		var { width, height } = Dimensions.get('window');
		this.setState({
			windowWidth: width,
			windowHeight: height
		}); 
	};

	frame_web(body) {
		return (
			<View style={Styles.splash_container} onLayout={this.onLayout}>
				<View style={Styles.splash_inner_frame} testID="inner-frame">
					{body}
				</View>
			</View>
		);
	}
	
	frame_mobile(body) {
        return (
            <View style={Styles.splash_container} onLayout={this.onLayout}>
                <ImageBackground
                //   source={require('../theme/homepage-bg.jpg')}
                  imageStyle={{resizeMode: 'cover'}}
                  style={Styles.splash_background}>
                    <View style={Styles.splash_inner_frame}>
                        {body}
                    </View>
                </ImageBackground>
            </View>
        );
	}

	render() {
		const { children } = this.props;
		if (Platform.OS == 'web') {
			return this.frame_web(children);
		} else {
			return this.frame_mobile(children);
		}
	}

};

