import "react-native-gesture-handler"; // THIS MUST BE FIRST  https://reactnavigation.org/docs/stack-navigator/
import React from "react";
import AppLoading from "expo-app-loading";
import * as Linking from "expo-linking";
import { SafeAreaProvider } from "react-native-safe-area-context";
import {
  LogBox,
  Platform,
  StatusBar,
  StyleSheet,
  View,
  Image,
  Text,
} from "react-native";
import NetInfo from "@react-native-community/netinfo";
import { Asset } from "expo-asset";
import * as Font from "expo-font";
import Resource from "./components/Resource";
import GlobalSettings from "./components/GlobalSettings";
import AppNavigator from "./components/AppNavigator";
import TitlePage from "./pages/TitlePage";
import ConfigPage from "./pages/Config";
import NavigationService from "./components/NavigationService";
import DropdownAlert from "react-native-dropdownalert";
import { DropDownHolder, DropdownType } from "./components/DropDownHolder";
import GestureRecognizer, {
  swipeDirections,
} from "./components/GestureRecognizer";
import ProcedureDownload from "./components/ProcedureDownload";
import Storage from "./components/Storage";
import Styles, { HeaderBar, documentTitle } from "./theme/Styles";
import ProcedureData from "./components/ProcedureData";
import MainTabNavigator, { ContentStack } from "./components/MainTabNavigator";
import Watch from "./components/Watch";

import { createIconSetFromIcoMoon } from "@expo/vector-icons";
import icoMoonConfig from "./theme/icomoon.json";
const expoAssetId = require("./theme/icomoon.ttf");
const IconMoon = createIconSetFromIcoMoon(
  icoMoonConfig,
  "icomoon",
  expoAssetId
);
import ReactDOM from "react-dom";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 0,
      isSplash: true,
      laststep: -1,
      lastIsSplash: null,
      connection_status: "",
    };
    this._restart = this._restart.bind(this);
    this._nav_title = this._nav_title.bind(this);
    this._incomingURL = this._incomingURL.bind(this);
    this._handleOpenURL = this._handleOpenURL.bind(this);
    this._getOnlineStatus = this._getOnlineStatus.bind(this) || null;
    this._cacheResourcesAsync = this._cacheResourcesAsync.bind(this);
    this.isInitialized = false;
    this.hasopen = false;
    this.hassplashed = false;
    this.splashref = React.createRef();
    this._isMounted = false;

    LogBox.ignoreLogs(["Warning: ..."]);
    LogBox.ignoreAllLogs();

    Resource.init();
  }

  static getDerivedStateFromProps(props, state) {
    let changed = false;
    let result = {};
    if (state.step != state.laststep) {
      changed = true;
      result.step = state.step;
      result.laststep = state.laststep;
      if (result.step == 3) {
        ProcedureData.setEmergencyNumber();
      }
    }
    if (state.isSplash != state.lastIsSplash || state.lastIsSplash === null) {
      changed = true;
      result.isSplash = state.isSplash;
      result.lastIsSplash = state.isSplash;
    }
    return changed ? result : null;
  }

  // App is open, but need to load in new Product
  _handleOpenURL(event) {
    this._incomingURL({ url: event.url, source: "open" });
  }

  // an incoming link - load requested product	looks for "?p=[product]"
  _incomingURL(param) {
    let nextproduct;
    let { url, source } = param;
    console.log("incoming url: ", url, " source: ", source);
    if (source == "initial" && this.hasopen) return;
    if (source == "open") this.hasopen = true; // we've received an incoming notification - ignore any 'initial' url
    if (url === null || url == "") {
      url = "http://example/";
    }
    let { queryParams } = Linking.parse(url);
    if (typeof queryParams.p !== "undefined") {
      nextproduct = queryParams.p;
    } else {
      nextproduct = null;
    }
    if (this.isInitialized && nextproduct !== null) {
      GlobalSettings.procedure_validate(
        nextproduct,
        Resource.getPreviewMode()
      ).then((value) => {
        this._restart({
          procedure: nextproduct, //			Storage.local_setValue('procedure', _detectedProduct);
          clear_all: true, // clear all
          //page: 1,				// set page 1
        });
      });
    }
    // otherwise delay until initialized
  }

  async componentDidMount() {
    //this.loadFonts();
    Storage.on("restart", this._restart);
    // on apple this will occur after the Linking listener
    Storage.on("initialurl", this._incomingURL); // app not open, and deeplink received
    Linking.addEventListener("url", this._handleOpenURL); // app already open and deeplink received

    Storage.on("nav_title", this._nav_title); // show Title Page

    this._isMounted = true;
    if (this._isMounted) {
      Storage.off("restart", this._restart);
      Storage.off("initialurl", this._incomingURL);
      // Linking.removeEventListener('url', this._handleOpenURL);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let node;
    if (
      (this.state.step == 3 || this.state.step == 6) &&
      !this.hassplashed &&
      this.splashref.current !== null
    ) {
      // intercept homescreen html to add ios or google banner
      if (Platform.OS == "web") {
        let content_text = ProcedureData.contentText();
        let selected_procedure = Storage.local_getValue("procedure", null);
        if (content_text.macro.mobile_app) {
          // allow the app to insert ios/android link
          node = ReactDOM.findDOMNode(this);
        } else {
          node = null;
        }
        let param = {
          proc: selected_procedure,
          container: node,
          step: this.state.step,
          content_text: content_text,
        };
        Resource.javascriptEvent("homescreen", param);
      }
      this.hassplashed = true;
    }
  }

  // display the splash screen
  _nav_title(param = {}) {
    //this.hassplashed = false;
    Storage.local_setValue("introduction", "0");
    ProcedureData.clear(); // go back to page 1
    this.setState({ step: 3 });
    // send event
    Watch.registerEvent(-100, "click");
  }

  _restart(param = {}) {
    let clearall =
      typeof param.clear_all !== "undefined" ? param.clear_all : false;
    if (clearall) {
      Resource.detectedProduct(null);
      ProcedureDownload.clear();
      //			GlobalSettings.clear();
      NavigationService.clear();
      ProcedureData.clear();
      this._cacheResourcesAsync();
    }
    // after we have cleared everything 'param.procedure' is the new procedure to be loaded
    let procedure =
      typeof param.procedure !== "undefined" ? param.procedure : null;
    if (procedure !== null) {
      Resource.detectedProduct(procedure);
      Storage.local_setValue("procedure", procedure);
    }
    this.setState({ step: 0, isSplash: true });
  }

  onDismiss = () => {
    this._checkGuideExpiry({ step: 4, isSplash: false });
  };

  _renderCancel = (props, alertData) => {
    let result = { value: undefined };
    Storage.trigger("dropdownButton", { props, alertData, result });
    return result.value;
  };

  _getOnlineStatus() {
    if (Platform.OS == "android" || Platform.OS == "ios") {
      return NetInfo.addEventListener((state) => {
        this.setState({ connection_status: state.type });
        ProcedureData.OnlineState(state.type);
      });
    } else if (
      Platform.OS === "macos" ||
      Platform.OS === "windows" ||
      Platform.OS === "web"
    ) {
      // Hardcoded 'wifi' as status because NetInfo isn't supported by React Native Web and it's understood that if connection is active that's how app is working.
      ProcedureData.OnlineState("wifi");
      return this.setState({ connection_status: "wifi" });
    }
  }

  async _cacheResourcesAsync() {
    const images = [require("./theme/homepage-bg.jpg")];

    const cacheImages = images.map((image) => {
      return Asset.fromModule(image).downloadAsync();
    });

    // this must be done before the product can be determined
    await Storage.init(); // load session - all the pai-* localStorage variables

    cacheImages.push(this._getOnlineStatus());

    if (!this.isInitialized) {
      // only do this the first time the app is loaded
      cacheImages.push(Resource.determineProduct()); // deep linking on iOS / Android.  subdomain on web
    }

    // what procedure guides are available.
    cacheImages.push(GlobalSettings.init()); // load master.json, if needed from remote site
    return Promise.all(cacheImages);
  }

  handleLoadingError(error) {
    console.log(error);
  }

  _resourcesLoaded_step2(procedure_info) {
    if (procedure_info === null) {
      Resource.detectedProduct(procedure_info);
      ProcedureDownload.clear(false); // clear out any old version
      this.setState({ step: 1 });
      return;
    }
    let desired_procedure = procedure_info.abbr;

    // Get the Procedure Data for the previously selected procedure.
    let selected_json = Storage.local_getValue(".data.json", false);
    if (selected_json === null) selected_json = false;
    if (
      this.state.connection_status !== "" &&
      this.state.connection_status !== "none" &&
      selected_json !== false
    ) {
      // online?
      let current = ProcedureData.getCurrentInfo();
      let productinfo = GlobalSettings.getProductInfo(desired_procedure);
      // database is for wrong product, or there's an newer version  available
      if (
        productinfo === null ||
        current.appname !== desired_procedure ||
        current.version < productinfo.version
      ) {
        selected_json = false;
      }
    }
    if (selected_json === false) {
      this.setState({ step: 2 });
    } else {
      this._checkGuideExpiry({ step: 3 });
    }
  }

  // move to step x if not expired, otherwise step 6 - display expiry message
  _checkGuideExpiry(newstate) {
    let expiry = ProcedureData.getExpiry();
    let now = parseInt(new Date() / 1000);
    if (expiry > 0 && now > expiry) {
      this.setState({ step: 6 });
    } else {
      this.setState(newstate);
    }
  }

  // The select product entered from the Config page takes priority
  //  overwritten by: version2/product parameter.
  //  overwritten by: deeplink parameter.
  //	 finally, if no product is specified, use the product from the url.

  _getprocedure() {
    let desired_procedure = Storage.local_getValue("procedure", null); // previously selected procedure
    desired_procedure = "pai-lcl";

    let detected_procedure = Resource.detectedProduct(); // from the url
    if (detected_procedure !== null && desired_procedure === null)
      desired_procedure = detected_procedure;

    if (desired_procedure !== null) {
      Storage.local_setValue("procedure", desired_procedure);
    } else {
      Storage.local_removeValue("procedure");
    }
    return desired_procedure;
  }

  _resourcesLoaded() {
    // if we need some info, next step will be step 1,
    // if not accepted agreement
    this.isInitialized = true;
    let introsetting = Storage.local_getValue("introduction", false);
    if (introsetting === false) Storage.local_setValue("introduction", "1");

    // make sure the detected procedure is valid - ignore it if it is not.
    let detected_procedure = Resource.detectedProduct(); // from the url
    if (detected_procedure !== null) {
      GlobalSettings.procedure_validate(
        detected_procedure,
        Resource.getPreviewMode()
      )
        .then((value) => {
          if (value === null) Resource.detectedProduct(null);
          detected_procedure = this._getprocedure();
          return GlobalSettings.procedure_validate(
            detected_procedure,
            Resource.getPreviewMode()
          );
        })
        .then((value) => {
          this._resourcesLoaded_step2(value);
        });
    } else {
      detected_procedure = this._getprocedure();
      GlobalSettings.procedure_validate(
        detected_procedure,
        Resource.getPreviewMode()
      ).then((value) => {
        this._resourcesLoaded_step2(value);
      });
    }
  }

  step0_loadvalues() {
    return (
      <AppLoading
        startAsync={this._cacheResourcesAsync}
        onError={this.handleLoadingError}
        onFinish={() => this._resourcesLoaded()}
      />
    );
  }

  // select language, accept agreement, select procedure, set country
  step1_config() {
    return <ConfigPage onFinish={() => this.setState({ step: 2 })} />;
  }

  step2_loadResources() {
    // use async with componentDidMount
    // new component/js page
    return (
      <ProcedureDownload
        onFinish={() => {
          ProcedureData.clear();
          this._checkGuideExpiry({ step: 3 });
        }}
      />
    );
  }

  step3_splash(expired) {
    // calendar is initialized while Splash page is displayed.
    // show days remaining in trial
    let content_text = ProcedureData.contentText();
    documentTitle(content_text.splash.header);
    MainTabNavigator.showCareTeam(ProcedureData.hasCareTeam());
    const config = {
      velocityThreshold: 0.3,
      directionalOffsetThreshold: 80,
    };
    if (expired) {
      documentTitle("Trial has Expired");
      return (
        <TitlePage
          expired={1}
          onFinish={() => this.setState({ step: 2 })}
          ref={(node) => {
            this.splashref.current = node;
          }}
        />
      );
    }
    return (
      <GestureRecognizer
        onSwipeLeft={(state) => {
          this.onDismiss();
        }}
        style={styles.container}
        config={config}
      >
        <TitlePage
          expired={0}
          dismiss={this.onDismiss}
          ref={(node) => {
            this.splashref.current = node;
          }}
        />
      </GestureRecognizer>
    );
  }

  step4_main() {
    //			<PickListContainer ref={ref => DropDownHolder.setPickList(ref)} />
    // Make sure DropdownAlert is the last component in the document tree.
    return (
      <SafeAreaProvider>
        <View style={[styles.container, { width: "100%", height: "100%" }]}>
          {Platform.OS === "ios" && <StatusBar barStyle="default" />}
          <AppNavigator style={{ backgroundColor: "transparent" }} />
          <DropdownAlert
            ref={(ref) => DropDownHolder.setDropDown(ref)}
            closeInterval={0}
            showCancel={true}
            renderCancel={this._renderCancel}
          />
        </View>
      </SafeAreaProvider>
    );
  }

  render() {
    // console.disableYellowBox = true;
    switch (this.state.step) {
      case 0: // load Loading...
        return this.step0_loadvalues();

      case 1: // do Config
        return this.step1_config();

      // do downloading of selected resource
      case 2:
        return this.step2_loadResources();
        break;

      case 3: // do Splash
        return this.step3_splash(false);

      case 6: // expired
        return this.step3_splash(true);

      case 4:
      default: // do mainscreen
        return this.step4_main();
    }
    return (
      <View>
        <Text>Unexpected Error</Text>
      </View>
    );
  }
}

export default App;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});
